import React from 'react';
import { Grid } from '@mui/material';
import duckImg from "../assets/images/duck.png";
import errorImg from "../assets/images/404.png"
import { useNavigate } from 'react-router-dom';

const PageNotFound = ({ cmsOpen }: { cmsOpen: any }) => {
  const navigate = useNavigate();
  return (
    <Grid item container direction="column" justifyContent='center' alignItems='center' marginTop={15} rowGap={2} width={localStorage.getItem('isCMS') === 'true' ?cmsOpen? '80vw': '90vw' : '100%'}>
      <Grid item><img src={errorImg} alt='icon' width='300px' /></Grid>
      <Grid item className='neutral-3 labels' textAlign='center'> Something is missing.<br />
        We can’t seem to find the page you are looking for.</Grid>
      <Grid item>
        <button className='blueButton'
          onClick={() => {
            if (localStorage.getItem('isCMS') === 'true') navigate('/admin-panel/users')
            else navigate('/')

          }}
        >Go Back Home</button></Grid>
    </Grid>
  )
}

export default PageNotFound