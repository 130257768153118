import { ComponentPropsWithoutRef, ReactNode } from "react";
import { FormattedMessage as IntlFormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';

export type MessageProps = ComponentPropsWithoutRef<typeof Typography> & {
    id: string;
    defaultMessage?: string;
    append?: ReactNode;
    clasName?: string;
};

export default function Message(props: MessageProps) {
    const { id, defaultMessage, append, className, ...typographyProps } = props;
    return (
        <IntlFormattedMessage 
            id={props.id}
            defaultMessage={props.defaultMessage}
        >
            {(m) => 
                <Typography {...typographyProps}>
                    <div className={className}>
                    {m}{append || null}
                    </div>
                </Typography>
            }
        </IntlFormattedMessage>
    );
}