import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid, TextField } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useAppSelector } from '../../../app/hooks';
import { getCallStatus, getErrorMsg } from '../../../app/slices/apiCallSlice';
import { getLoadingState } from '../../../app/slices/loadingSlice';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { useNavigate } from 'react-router-dom';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import classes from "../../../assets/styles/User.module.css";
import CheckIcon from '@mui/icons-material/Check';
import Message from '../../../sharedComponents/ui/Message';
import { useIntl } from 'react-intl';
import * as UserRolesConsts from "../../../user-roles-consts";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: '0px 2px 4px 0px #0000001F',
    backgroundColor: '#FEFEFF',
    borderRadius: '10px',
    padding: 0,
    color: 'black',
  },
}));

export interface UserProps {
  activeTab: any;
  setActiveSection: any;
  addedTms: any;
  setAddedTms: any;
  roles: any;
  addedClients: any;
  setAddedClients: any;
}

const AddUser = (props: UserProps) => {
  const { activeTab, setActiveSection, addedTms, setAddedTms, roles, addedClients, setAddedClients } = props

  const navigate = useNavigate();
  const intl = useIntl();

  const [lineIndex, setLineIndex] = useState<number>();
  const [tmpCurrentTab, setTmpCurrentTab] = useState<string>();

  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState<string>('');

  const [nameError, setNameError] = useState<boolean>(false);
  const [clientError, setClientError] = useState<boolean>(false);

  const [deleteIconHover, setDeleteIconHover] = useState<boolean>(false);

  const [listError, setListError] = useState<boolean>(false);

  const loading = useAppSelector(getLoadingState);
  const errorMsg = useAppSelector(getErrorMsg);
  const callStatus = useAppSelector(getCallStatus);

  const [, updateState] = React.useState({});

  // Create a memoized callback to force re-render
  const forceUpdate = React.useCallback(() => {
    // Updating state with an empty object to trigger a re-render
    updateState({});
  }, []);

  const addUserObject = () => {
    console.log("adding")
    const listToCheck = activeTab === 'Tms' ? addedTms : addedClients;

    const allUsersValid = listToCheck.every((user: { email: string; roles: string | any[]; }) =>
      user.email.trim() !== "" && user.roles.length > 0
    );
    if (allUsersValid) {
      const tmUser = {
        id: addedTms.length + 1, // Unique id for the new user
        roles: [],
        email: "",
      };
      const clientUser = {
        id: addedClients.length + 1, // Unique id for the new user
        roles: [UserRolesConsts.CLIENT_ADMIN_ID],
        email: "",
        name: "",
        clientNumber:"",
      };
      if (activeTab === 'Tms') {
        setAddedTms([...addedTms, tmUser]);
      }
      if (activeTab === 'Clients') {
        setAddedClients([...addedClients, clientUser]);
      }

    }
    else setListError(true)
  }

  return (
    <Grid item container direction='column' padding={3} rowGap={1} justifyContent='center'>
      <Grid item className='black desktop-header-5'>
        {activeTab === 'Tms' ? <Message id="text.add-your-team" className="Field-input desktop-header-5 font-weight-400" />
          :
          <Message id="text.add-your-clients" className="Field-input desktop-header-5 font-weight-400" />
        } </Grid>
      <Grid item className='black labels'> <Message id="text.add-multiple-users" className="Field-input labels font-weight-400" /></Grid>
      <Grid item container direction='row' className='black labels' columnGap={2} marginTop={3}>
        <Grid item xs={activeTab === 'Tms' ?4:2.5}>  {activeTab === 'Tms' ? <Message id="ta.add.role" className="Field-input labels font-weight-400" />
          :
          <Message id="ta.add.level-of-access" className="Field-input labels font-weight-400" />
        } </Grid>
        {activeTab === 'Clients' ?
        <>
          <Grid item xs={2.5}><Message id="ta.add.name" className="Field-input labels font-weight-400" /></Grid>
          <Grid item xs={2}>Client Number *</Grid>
          </>
          :
          <></>
        }
        <Grid item xs={3}> <Message id="ta.add.email" className="Field-input labels font-weight-400" /></Grid>
      </Grid>
      {activeTab === 'Tms' ?

        addedTms.map((val: any, index: number) => (
          <Grid item container key={val.id} direction='row' columnGap={2}>
            <Grid item xs={4} container className='textfield' columnGap={1} rowGap={1}>
              {val.roles.map((role: any) => (
                <Grid item key={role} className={classes['roleBox']}>{roles?.find((r: { id: any; }) => r.id === role)?.name}</Grid>
              ))}
              <Grid item>
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Grid item container rowGap={1} maxHeight='100px' padding={1} display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                        {roles?.filter((r: { typeId: number; }) => (localStorage.getItem('userType') === '1' ? r.typeId === 1 : r.typeId === 2))?.map((role: any, index: number) => (
                          <Grid item container alignItems='center' key={role.id} columnGap={1} className={classes[lineIndex === index || val.roles?.includes(role.id) ? 'filter-selected' : 'filter-not-selected']} justifyContent='flex-start'
                            onMouseOver={(e) => {
                              const target = e.target as HTMLElement;
                              // if (!val.roles?.includes(role.id)) {
                              target.style.cursor = 'pointer';
                              setLineIndex(index);
                              // }
                            }}
                            onMouseOut={() => {
                              setLineIndex(-1);
                            }}
                            onClick={(e) => {
                              if (!val.roles?.includes(role.id)) {
                                const updatedRoles = [...val.roles, role.id];
                                val.roles = updatedRoles;
                                forceUpdate();
                                // setSelectedRoles((prevTenants: any) => [...prevTenants, val.id]);
                              }
                              else {
                                const updatedRoles = val.roles.filter((r: { id: any; }) => r !== role.id)
                                val.roles = updatedRoles;
                                forceUpdate();
                              }
                            }}
                          >
                            <Grid className='bordered-box' bgcolor={lineIndex === index || val.roles?.includes(role.id) ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={lineIndex === index || val.roles?.includes(role.id) ? '#00B69B' : '#9E9E9E'} /></Grid>
                            <Grid item>{role.name}</Grid>
                          </Grid>
                        ))}

                      </Grid>
                    </React.Fragment>
                  }
                >
                  <ControlPointIcon htmlColor='#C3C2C7' style={{ cursor: 'pointer' }} />

                </HtmlTooltip >
              </Grid>

            </Grid>
            <Grid item xs={3}>
              <TextField
                value={val.email}
                placeholder={intl.formatMessage({ id: 'login.enter-your_email-here' })}
                className="Field-input"
                InputLabelProps={{ shrink: true }}
                error={lineIndex === index && tmpCurrentTab === 'Tms' ? emailError : false}
                helperText={emailError && lineIndex === index && tmpCurrentTab === 'Tms' ? emailErrorMsg : ''}
                size='medium'
                InputProps={{
                  style: {
                    borderRadius: '12px',
                    fontFamily: 'DM Sans',
                    height: '50px',
                  },
                }}
                onChange={(e) => {
                  setLineIndex(index);
                  setTmpCurrentTab('Tms');
                  val.email = e.target.value;
                  forceUpdate();
                  console.log("tms", addedTms)
                  if (!e.target.value || !e.target.value.match(
                    /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
                  )) {
                    setEmailError(true);
                    setEmailErrorMsg(intl.formatMessage({ id: 'error.enter-a-valid-email-address' }))
                  }
                  else {
                    setEmailError(false);
                    setEmailErrorMsg('')
                  }
                }}
                onBlur={(e) => {
                  if (!e.target.value) {
                    setEmailError(true);
                    setEmailErrorMsg(intl.formatMessage({ id: 'error.enter-a-valid-email-address' }))
                  }
                }}
              />
            </Grid>
            <Grid item style={{ cursor: 'pointer' }} paddingTop={1}
              onMouseOver={(e) => {
                setLineIndex(index);
                setDeleteIconHover(true);
              }}
              onMouseOut={() => {
                setLineIndex(-1);
                setDeleteIconHover(false);
              }}
              onClick={() => {
                setAddedTms((prevTms: any[]) => prevTms.filter(tm => tm.id !== val.id));
              }}>
              <DeleteOutlineIcon htmlColor={lineIndex === index && deleteIconHover && activeTab === 'Tms' ? "#EF4737" : "#93919a"} />
            </Grid>
          </Grid>
        ))
        :
        addedClients.map((val: any, index: number) => (
          <Grid item container key={val.id} direction='row' columnGap={2}>
            <Grid item xs={2.5} container className='textfield' columnGap={1} rowGap={1} alignItems='center'>
              {val.roles.map((role: any) => (
                <Grid item key={role} className={classes['roleBox']}>{roles?.find((r: { id: any; }) => r.id === role)?.name}</Grid>
              ))}
              {/* <Grid item>
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Grid item container rowGap={1} maxHeight='100px' padding={1} display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                        {roles?.filter((r: { typeId: number; }) => r.typeId === 2)?.map((role: any, index: number) => (
                          <Grid item container alignItems='center' key={role.id} columnGap={1} className={classes[lineIndex === index || val.roles?.includes(role.id) ? 'filter-selected' : 'filter-not-selected']} justifyContent='flex-start'
                            onMouseOver={(e) => {
                              const target = e.target as HTMLElement;
                              // if (!val.roles?.includes(role.id)) {
                              target.style.cursor = 'pointer';
                              setLineIndex(index);
                              // }
                            }}
                            onMouseOut={() => {
                              setLineIndex(-1);
                            }}
                            onClick={(e) => {
                              if (!val.roles?.includes(role.id)) {
                                const updatedRoles = [...val.roles, role.id];
                                val.roles = updatedRoles;
                                forceUpdate();
                                // setSelectedRoles((prevTenants: any) => [...prevTenants, val.id]);
                              }
                              else {
                                const updatedRoles = val.roles.filter((r: { id: any; }) => r !== role.id)
                                val.roles = updatedRoles;
                                forceUpdate();
                              }
                            }}
                          >
                            <Grid className='bordered-box' bgcolor={lineIndex === index || val.roles?.includes(role.id) ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={lineIndex === index || val.roles?.includes(role.id) ? '#00B69B' : '#9E9E9E'} /></Grid>
                            <Grid item>{role.name}</Grid>
                          </Grid>
                        ))}

                      </Grid>
                    </React.Fragment>
                  }
                >
                  <ControlPointIcon htmlColor='#C3C2C7' style={{ cursor: 'pointer' }} />
                </HtmlTooltip >
              </Grid> */}
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                value={val.name}
                placeholder={intl.formatMessage({ id: 'ta.enter-name' })}
                className="Field-input"
                InputLabelProps={{ shrink: true }}
                error={lineIndex === index && tmpCurrentTab === 'Clients' ? nameError : false}
                helperText={nameError && lineIndex === index && tmpCurrentTab === 'Clients' ? intl.formatMessage({ id: 'error.enter-a-name' }) : ''}
                size='medium'
                InputProps={{
                  style: {
                    borderRadius: '12px',
                    fontFamily: 'DM Sans',
                    height: '50px',
                  },
                }}
                onChange={(e) => {
                  setLineIndex(index);
                  setTmpCurrentTab('Clients');
                  val.name = e.target.value;
                  forceUpdate();
                  if (!e.target.value) {
                    setNameError(true);
                  }
                  else {
                    setNameError(false);
                  }
                }}
                onBlur={(e) => {
                  if (!e.target.value) {
                    setNameError(true);
                  }
                }}
              />
            </Grid>

            <Grid item xs={2}>
              <TextField
                value={val.clientNumber}
                placeholder='Enter Client Nb'
                className="Field-input"
                InputLabelProps={{ shrink: true }}
                error={lineIndex === index && tmpCurrentTab === 'Clients' ? clientError : false}
                helperText={clientError && lineIndex === index && tmpCurrentTab === 'Clients' ? 'Enter Client Number' : ''}
                size='medium'
                InputProps={{
                  style: {
                    borderRadius: '12px',
                    fontFamily: 'DM Sans',
                    height: '50px',
                  },
                }}
                onChange={(e) => {
                  setLineIndex(index);
                  setTmpCurrentTab('Clients');
                  val.clientNumber = e.target.value;
                  forceUpdate();
                  if (!e.target.value) {
                    setClientError(true);
                  }
                  else {
                    setClientError(false);
                  }
                }}
                onBlur={(e) => {
                  if (!e.target.value) {
                    setClientError(true);
                  }
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                value={val.email}
                placeholder={intl.formatMessage({ id: 'login.enter-your_email-here' })}
                className="Field-input"
                InputLabelProps={{ shrink: true }}
                error={lineIndex === index && tmpCurrentTab === 'Clients' ? emailError : false}
                helperText={emailError && lineIndex === index && tmpCurrentTab === 'Clients' ? emailErrorMsg : ''}
                size='medium'
                InputProps={{
                  style: {
                    borderRadius: '12px',
                    fontFamily: 'DM Sans',
                    height: '50px',
                  },
                }}
                onChange={(e) => {
                  setLineIndex(index);
                  setTmpCurrentTab('Clients');
                  val.email = e.target.value;
                  forceUpdate();
                  if (!e.target.value || !e.target.value.match(
                    /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
                  )) {
                    setEmailError(true);
                    setEmailErrorMsg(intl.formatMessage({ id: 'error.enter-a-valid-email-address' }))
                  }
                  else {
                    setEmailError(false);
                    setEmailErrorMsg('')
                  }
                }}
                onBlur={(e) => {
                  if (!e.target.value) {
                    setEmailError(true);
                    setEmailErrorMsg(intl.formatMessage({ id: 'error.enter-a-valid-email-address' }))
                  }
                }}
              />
            </Grid>
            <Grid item style={{ cursor: 'pointer' }} paddingTop={1}
              onMouseOver={(e) => {
                setLineIndex(index);
                setDeleteIconHover(true);
              }}
              onMouseOut={() => {
                setLineIndex(-1);
                setDeleteIconHover(false);
              }}
              onClick={() => {
                setAddedClients((prevTms: any[]) => prevTms.filter(tm => tm.id !== val.id));
              }}>
              <DeleteOutlineIcon htmlColor={lineIndex === index && deleteIconHover && activeTab === 'Clients' ? "#EF4737" : "#93919a"} />
            </Grid>

          </Grid>

        ))
      }

      <Grid item className='iris-100 bold' marginTop={2}
        onClick={() => {
          addUserObject()
        }}
      >
        <HtmlTooltip placement='bottom-start'
          title=
          {
            <Grid item padding={1}>

              {listError ? <Message id="text.user-missing-field" className="Field-input labels font-weight-400" /> : <Message id="text.click-to-add-user" className="Field-input labels font-weight-400" />}

            </Grid>
          }>
          <span style={{ cursor: 'pointer' }}><Message id="ta.add.users" className="Field-input labels labels-extra-bold" /></span>
        </HtmlTooltip>
      </Grid>
      <Grid item container direction='row' justifyContent='flex-end' marginTop={3} marginBottom={2} columnGap={1}>
        <Grid item xs={2.5} >
          <button className='greyButton'
            onClick={() => navigate('/users')}
          ><Message id="button.cancel" className="Field-input labelsLinks labels-extra-bold" /></button>
        </Grid>
        <Grid item xs={2.5}>
          <button
            className={callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'blueButton'}
            disabled={loading || (activeTab === 'Tms' && !addedTms.every((user: { email: string; roles: string | any[]; }) =>
              user.email.trim() !== "" && user.roles.length > 0))
              || (activeTab === 'Clients' && !addedClients.every((user: { email: string; name: string; roles: string | any[]; }) =>
                user.email.trim() !== "" && user.name.trim() !== "" && user.roles.length > 0))}

            onClick={() => {
              setActiveSection('Review')}
            }
               >
            {loading ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
              : callStatus === 'Pass' ? <span><CheckCircleOutlinedIcon style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                : callStatus === 'Fail' ? "Failed"
                  : <Message id="button.next" className="Field-input labelsLinks labels-extra-bold" />}
          </button>
          <Grid item textAlign='center' className='errorText' paddingTop={1}>{errorMsg}</Grid>
        </Grid>
      </Grid>




    </Grid>
  )
}

export default AddUser