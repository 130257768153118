import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import * as stripeServices from "../services/stripe-services.proxy";
import { loadStripe } from '@stripe/stripe-js';
import StatusDialog from '../components/Payment/StatusDialog';
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { setLoading, getLoadingState } from '../app/slices/loadingSlice';
import classes from "../assets/styles/Login.module.css";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { setErrorMsg, getErrorMsg } from '../app/slices/apiCallSlice';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';


const checkoutSession = stripeServices.checkoutSession;

const CustomSlider = styled(Slider)({
  color: '#00B69B',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '25% 25% 25% 0',
    backgroundColor: '#FEFEFF',
    color: "#000",
    transformOrigin: 'bottom left',
    // transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    // '&::before': { display: 'none' },
    // '&.MuiSlider-valueLabelOpen': {
    //   transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    // },
    // '& > *': {
    //   transform: 'rotate(45deg)',
    // },
  },
});

const Payment = (
  //{ setIsSubscribed }: { setIsSubscribed: any }
) => {

  const [open, setOpen] = React.useState(false);

  const dispatch = useAppDispatch();
  const loading = useAppSelector(getLoadingState);
  const errorMsg = useAppSelector(getErrorMsg);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  const [paymentStatus, setPaymentStatus] = useState<string>('');

  const [val, setVal] = React.useState<number>(20);
  const handleChange = (_: Event, newValue: number | number[]) => {
    setVal(newValue as number);
  };

  useEffect(() => {
    let queryStatus = query.get("status");
    if (queryStatus) setPaymentStatus(queryStatus)
    if (paymentStatus) handleClickOpen();
  }, [paymentStatus, loading])


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPaymentStatus('')
  };

  const checkoutSessionCall = () => {
    dispatch(setLoading(true));
    setPaymentStatus('loading')
    checkoutSession().then(async (x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
      }
      else {
        const stripePromise = loadStripe('pk_test_51Pb1P32LgdWXCKvIeuyrZR5MlEmJ2Y555Rq7XCD2H42fiDmbay7LG1foqkZSVACZn0w0r7Hdp4rIWuzgvn6MvEB600AGWNT7fg');
        // Get Stripe.js instance
        const stripe = await stripePromise;
        if (stripe) {
          // Redirect to Checkout
          stripe.redirectToCheckout({ sessionId: x.sessionId });
        }
      }
      setPaymentStatus('')
      dispatch(setLoading(false));
    })

  }

  // onClick={() => navigate(`/payment?status=fail`)}

  return (
  
    <Grid container justifyContent='center' direction='column' alignItems='center' bgcolor='#f3f4f8' height='100vh'>
      <Grid item container direction='column' padding={4} className='dialogContainer' width='30vw' marginTop={2}>          <Grid item className='neutral-6 labels'>VIEW QUOTE AND</Grid>
          <Grid item className='neutral-6 desktop-header-5' paddingBottom={1}>Proceed To Payment</Grid>
          <Grid container className={classes['paymentContainer']} direction='column' width='380px' padding={3} rowGap={1.1}>
            <Grid item alignSelf='center' className='labels-extra-bold'>Pro Plan</Grid>
            <Grid item alignSelf='center' className='desktop-header-4'>€200</Grid>
            <Grid item>Modules Included:</Grid>
            <Grid item container rowGap={1} marginLeft={3}>
              <Grid item container alignItems='center' columnGap={1} className='labels'><CheckCircleIcon fontSize='small' />Module name 1 Placeholder</Grid>
              <Grid item container alignItems='center' columnGap={1} className='labels'><CheckCircleIcon fontSize='small' />Module name 1 Placeholder</Grid>
              <Grid item container alignItems='center' columnGap={1} className='labels'><CheckCircleIcon fontSize='small' />Module name 1 Placeholder</Grid>
            </Grid>
            <Grid item container direction='column' rowGap={1}>
              <Grid item className='labels-extra-bold'>Onboarding Fee</Grid>
              <Grid item style={{ fontWeight: 'normal', fontSize: '18px' }} marginLeft={4}>€200</Grid>
            </Grid>
            <Grid item container direction='column' rowGap={1}>
              <Grid item className='labels-extra-bold'>Number of clients free with package</Grid>
              <Grid item style={{ fontWeight: 'normal', fontSize: '16px' }} marginLeft={4}>30</Grid>
            </Grid>
            <Grid item container direction='row' padding={1} columnGap={1.8} justifyContent='center'>
              <Grid item><GroupRemoveIcon /></Grid>
              <Grid item width='180px'>
                <CustomSlider
                  valueLabelDisplay="auto"
                  aria-label="pretto slider"
                  defaultValue={20}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item>
                <GroupRemoveIcon />
              </Grid>
            </Grid>
            <Grid item container direction='column' rowGap={1} alignItems='center'>
              <Grid item className='labels-extra-bold'>Total Calculated Cost  (€5 per client) </Grid>
              <Grid item className='desktop-header-5' width='300px' textAlign='center'>€{(5* val).toFixed(2)} <span className='labels'> / {val} clients </span></Grid>
            </Grid>

            <Grid item width='250px' alignSelf='center'><button className={classes['proceedButton']} style={{ padding: '15px' }} onClick={() => checkoutSessionCall()}>Proceed</button></Grid>
            {errorMsg ?
              <Grid item className='errorText'>{errorMsg}</Grid> :
              <></>}
          </Grid>
          <StatusDialog
            open={open}
            status={paymentStatus}
            onClose={handleClose}
          />
        </Grid>
        </Grid>

  )
}

export default Payment