import React from 'react';
import fail from '../../assets/images/icons/fail.png'
import { Grid } from '@mui/material';
import { getErrorMsg, setErrorMsg } from '../../app/slices/apiCallSlice';
import { useAppSelector } from '../../app/hooks';
import { useAppDispatch } from '../../app/hooks';

const SSOFail = ({setLoginFormState}:{setLoginFormState:any}) => {

    const errorMsg = useAppSelector(getErrorMsg);
    const dispatch = useAppDispatch();


    return (
        <Grid item container direction='column' marginTop={6} rowGap={2} justifyContent='center' alignItems='center'>
            <Grid item>
                <img src={fail} alt='icon' width="275px" height="275px" />
            </Grid>
            <Grid item className='errorText desktop-header-5'>Unable to connect to server</Grid>
            {/* <Grid item className='neutral-6 labels'>Redirecting you to login page .</Grid> */}
            {errorMsg ?
                <Grid item className='errorText'>{errorMsg}</Grid>
                :
                <></>}
            <Grid item><button className='blueButton'
                onClick={() => {
                    setLoginFormState('default');
                    localStorage.setItem('loginFormState', 'default');
                    dispatch(setErrorMsg(''));
                }}>Go Back</button></Grid>

        </Grid>
    )
}

export default SSOFail