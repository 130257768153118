import * as apiConst from "../taxmation-consts";
import axios from "axios";

const CHECKOUT_SESSION = apiConst.CHECKOUT_SESSION
export const checkoutSession = () => {
    return axios
        .post(`${process.env.REACT_APP_URL + CHECKOUT_SESSION}`
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}

