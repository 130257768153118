import React, { useEffect, useState } from 'react';
import { DataGrid, GridColumnMenuProps, GridColDef } from '@mui/x-data-grid';
import { Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CheckIcon from '@mui/icons-material/Check';
import classes from "../../../assets/styles/Table.module.css";

export interface TableProps {
    rows: [Object];
    loading: boolean;
    switchCompanyStatusCall: (id: string) => void;
    statusId: number,
    setStatusId: CallableFunction;
    typeId: any;
    setTypeId: any;
    dataLength: any;
    setDataLength: any;
    paginationModel: any;
    setPaginationModel: any;

}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        boxShadow: '0px 2px 4px 0px #0000001F',
        backgroundColor: '#FEFEFF',
        padding: '10px',
        borderRadius: '10px'
    },
}));

// Define the custom column menu component
const CustomColumnMenu = (props: GridColumnMenuProps) => {
    return (
        <div>
            <button onClick={() => console.log('Custom Action')}>Custom Action</button>
        </div>
    );
};

// Define the DataGrid with custom column menu
const Table = (props: TableProps) => {

    const { rows, loading, statusId, setStatusId, switchCompanyStatusCall , typeId, setTypeId, dataLength, setDataLength, paginationModel, setPaginationModel} = props;

    const planStatuses: string[] = ['Active', 'Inactive']
    const typeStatuses: string[] = ['Client', 'Tax Advisor']

    const [filterIconHovered, setFilterIconHovered] = useState(false);
    const [filterIconIndex, setFilterIconIndex] = useState('');
    const [filterIndex, setFilterIndex] = useState<number>();

    const [tmpStatusId, setTmpStatusId] = useState();

    const columns: GridColDef[] = [

        {
            field: 'name', headerName: 'Company Name', width: 220, headerClassName: 'table-header',
        },
        { field: 'email', headerName: 'Company Email', width: 270, headerClassName: 'table-header', },
        { field: 'clientCount', headerName: 'Clients Onboarded', width: 200, headerClassName: 'table-header' },
        {
            field: 'type', headerName: 'Type', width: 160, headerClassName: 'table-header',
            renderHeader(params: any) {
                return (
                    <Grid item container className='table-header' alignItems='center' fontWeight='normal'>Type
                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <Grid item container direction='column' rowGap={1} width='150px'>
                                        {typeStatuses.map((val: string, index: number) => (
                                            <Grid item container alignItems='center' columnGap={1} className={classes[filterIndex === index || (typeId === 1 && val === 'Tax Advisor') || (typeId === 2 && val === 'Client') ? 'filter-selected' : 'filter-not-selected']} justifyContent='flex-start'
                                                onMouseOver={(e) => {
                                                    const target = e.target as HTMLElement;
                                                    if ((typeId !== 1 && val === 'Tax Advisor') || (typeId !== 2 && val === 'Client')) {
                                                        target.style.cursor = 'pointer';
                                                        setFilterIndex(index);
                                                    }
                                                }}
                                                onMouseOut={() => {
                                                    setFilterIndex(-1);
                                                }}
                                                onClick={() => {
                                                    if (val === 'Tax Advisor') setTypeId(1)
                                                    if (val === 'Client') setTypeId(2)

                                                }}
                                            >
                                                <Grid className='bordered-box' bgcolor={filterIndex === index || (typeId === 1 && val === 'Active') || (typeId === 2 && val === 'Inactive') ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={filterIndex === index || (typeId === 1 && val === 'Active') || (typeId === 2 && val === 'Inactive') ? '#00B69B' : '#9E9E9E'} /></Grid>
                                                <Grid item>{val}</Grid>
                                            </Grid>
                                        ))}
                                        <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} style={{ cursor: typeId ? 'pointer' : 'unset' }} marginLeft={1.5}
                                            onClick={() => {
                                                setTypeId(null);
                                            }}><RestartAltIcon fontSize='small' /> Remove filter</Grid>
                                    </Grid>
                                </React.Fragment>
                            }
                        >
                            <ArrowDropDownIcon />
                        </HtmlTooltip >
                    </Grid >
                )
            },
        },
        {
            field: 'taxAdvisory', headerName: 'Associated Tax Advisor', width: 200, headerClassName: 'table-header',
            renderCell: (params) => {
                return (
                    <span> {params.value ? params.value : "N/A"}</span>
                )
            }
        },
        {
            field: 'status',
            headerName: 'Plan Status',
            headerClassName: 'table-header',
            renderHeader(params: any) {
                return (
                    <Grid item container className='table-header' alignItems='center' fontWeight='normal'>Plan Status
                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <Grid item container direction='column' rowGap={1} width='150px'>
                                        {planStatuses.map((val: string, index: number) => (
                                            <Grid item container alignItems='center' columnGap={1} className={classes[filterIndex === index || (statusId === 1 && val === 'Active') || (statusId === 2 && val === 'Inactive') ? 'filter-selected' : 'filter-not-selected']} justifyContent='flex-start'
                                                onMouseOver={(e) => {
                                                    const target = e.target as HTMLElement;
                                                    if ((statusId !== 1 && val === 'Active') || (statusId !== 2 && val === 'Inactive')) {
                                                        target.style.cursor = 'pointer';
                                                        setFilterIndex(index);
                                                    }
                                                }}
                                                onMouseOut={() => {
                                                    setFilterIndex(-1);
                                                }}
                                                onClick={() => {
                                                    if (val === 'Active') setStatusId(1)
                                                    if (val === 'Inactive') setStatusId(2)

                                                }}
                                            >
                                                <Grid className='bordered-box' bgcolor={filterIndex === index || (statusId === 1 && val === 'Active') || (statusId === 2 && val === 'Inactive') ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={filterIndex === index || (statusId === 1 && val === 'Active') || (statusId === 2 && val === 'Inactive') ? '#00B69B' : '#9E9E9E'} /></Grid>
                                                <Grid item>{val}</Grid>
                                            </Grid>
                                        ))}
                                        {/* <Grid item container alignItems='center' columnGap={1} className={classes[statusId === 1 ? 'filter-selected' : 'filter-not-selected']} justifyContent='flex-start'
                                            onMouseOver={() => {
                                                if (statusId !== 1) {
                                                    setFilterIconIndex('active')
                                                }
                                            }}
                                            onMouseOut={() => {
                                                setFilterIconIndex('')
                                            }}
                                        >
                                            <Grid className='bordered-box' bgcolor={statusId === 1 || filterIconIndex === 'active' ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={statusId === 1 || filterIconIndex === 'active' ? '#00B69B' : '#9E9E9E'} /></Grid>
                                            <Grid item>Active</Grid>
                                        </Grid>
                                        <Grid item container alignItems='center' columnGap={1} className={classes[statusId === 2 ? 'filter-selected' : 'filter-not-selected']} justifyContent='flex-start'
                                            onMouseOver={() => {
                                                if (statusId !== 2) {
                                                    setFilterIconIndex('in-active')
                                                }
                                            }}
                                            onMouseOut={() => {
                                                setFilterIconIndex('')
                                            }}
                                        >
                                            <Grid className='bordered-box' bgcolor={statusId === 2 || filterIconIndex === 'in-active' ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={statusId === 2 || filterIconIndex === 'in-active' ? '#00B69B' : '#9E9E9E'} /></Grid>
                                            <Grid item>Inactive</Grid>
                                        </Grid> */}
                                        <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} style={{ cursor: statusId ? 'pointer' : 'unset' }} marginLeft={1.5}
                                            onClick={() => {
                                                setStatusId(null);
                                            }}><RestartAltIcon fontSize='small' /> Remove filter</Grid>
                                        {/* <Grid item container width='80px' marginLeft={7}>
                                            <button className='blueButton' disabled={loading}>Ok</button></Grid> */}
                                    </Grid>
                                </React.Fragment>
                            }
                        >
                            <ArrowDropDownIcon />
                        </HtmlTooltip >
                    </Grid >
                )
            },
            renderCell: (params) => {
                return (
                    <Grid item container direction='row' style={{ marginTop: '10px', height: '15px' }} columnGap={1.5}>
                        <Grid item className={params.value === 'Active' ? 'active-status' : 'not-active-status'} width='70px'>
                            {params.value}
                        </Grid>
                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <Grid item container direction='column' padding={1} rowGap={1} width='150px'>
                                        <Grid item
                                            // className={params.value === 'Active' ? 'selected-item ' : 'not-selected-item '}
                                            className='selected-item'
                                        // style={{ cursor: params.value === 'Active' ? 'unset' : 'pointer' }}

                                        >{params.value === 'Active' ? 'Deactivate ' : 'Activate '} </Grid>
                                        <Grid item container width='80px' marginLeft={7}
                                            onClick={() => {
                                                console.log("test", params.row.id)
                                                switchCompanyStatusCall(params.row.id)

                                            }}
                                        ><button className='blueButton' disabled={loading}>Ok</button></Grid>
                                    </Grid>
                                </React.Fragment>
                            }
                        >
                            <Grid className='bordered-box'><EditIcon htmlColor='#E6E6E8' fontSize='small' /></Grid>
                        </HtmlTooltip>
                    </Grid>
                );
            },
            width: 170,

        },
        { field: 'startDate', headerName: 'Start Date', width: 170, headerClassName: 'table-header', },
        {
            field: 'endDate',
            headerName: 'End Date',
            width: 170, headerClassName: 'table-header',
        },
        { field: 'nameOfPrimaryContact', headerName: 'Name of Primary Contact', width: 220, headerClassName: 'table-header' },
        { field: 'phoneNbOfPrimaryContact', headerName: 'Phone number of Primary Contact', width: 260, headerClassName: 'table-header' },
        { field: 'country', headerName: 'Country', width: 160, headerClassName: 'table-header' },
        { field: 'street', headerName: 'Street', width: 160, headerClassName: 'table-header' },
        { field: 'houseNb', headerName: 'House Number', width: 160, headerClassName: 'table-header' },
        { field: 'postalCode', headerName: 'Postal Code', width: 160, headerClassName: 'table-header' },

        // {
        //     field: 'actions',
        //     type: 'actions',
        //     width: 80,
        //     getActions: (params) => [
        //     //   <DeleteUserActionItem
        //     //     label="Delete"
        //     //     showInMenu
        //     //     icon={<DeleteIcon />}
        //     //     deleteUser={deleteUser(params.id)}
        //     //     closeMenuOnClick={false}
        //     //   />,
        //     ],
        //   },
    ];

    const handlePaginationModelChange = (model: any) => {
        setPaginationModel(model);
      };

    return (
        <div style={{ height: '53vh', width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                pagination
                paginationMode='server'
                paginationModel={paginationModel}
                onPaginationModelChange={handlePaginationModelChange} 
                rowCount={dataLength} 
                pageSizeOptions={[5, 10, 25]}
                // checkboxSelection
                disableColumnMenu
                sx={{
                    border: 2,
                    borderColor: '#FEFEFF',
                    '& .MuiDataGrid-cell:hover': {
                        color: 'primary.main',
                    },
                }}
            // slots={{
            //     columnMenu: CustomColumnMenu, // Use the custom column menu
            // }}
            />
        </div>
    );
};

export default Table;