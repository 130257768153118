
export const SUPER_ADMIN_ID = 1;
export const SUPER_ADMIN_NAME = 'Super Admin'

//Tax advisor roles
export const ADMIN_ID = 2;
export const ADMIN_NAME = 'Admin';

export const TAX_ADVISOR_ID = 3;
export const TAX_ADVISOR_NAME = 'Tax advisor';

export const REVIEWER_ID = 4;
export const REVIEWER_NAME = 'Reviewer';

export const CLERK_ID = 5;
export const CLERK_NAME = 'Clerk';

export const ANALYST_ID = 6;
export const ANALYST_NAME = 'Analyst';

//Client roles
export const CLIENT_ADMIN_ID = 7;
export const CLIENT_ADMIN_NAME = 'Client Admin';

export const CLIENT_AUHTORIZED_SIGNER_ID = 8;
export const CLIENT_AUHTORIZED_SIGNER_NAME = 'Client Authorized signer';

export const CLIENT_EMPLOYEE_ID = 9;
export const CLIENT_EMPLOYEE_NAME = 'Client Employee';

export const rolesAr = [
    {
        Id: SUPER_ADMIN_ID,
        Name: SUPER_ADMIN_NAME
    },
    {
        Id: ADMIN_ID,
        Name: ADMIN_NAME
    },
    {
        Id: TAX_ADVISOR_ID,
        Name: TAX_ADVISOR_NAME
    },
    {
        Id: REVIEWER_ID,
        Name: REVIEWER_NAME
    },
    {
        Id: CLERK_ID,
        Name: CLERK_NAME
    },
    {
        Id: ANALYST_ID,
        Name: ANALYST_NAME
    },
    {
        Id: CLIENT_ADMIN_ID,
        Name: CLIENT_ADMIN_NAME
    },
    {
        Id: CLIENT_AUHTORIZED_SIGNER_ID,
        Name: CLIENT_AUHTORIZED_SIGNER_NAME
    },
    {
        Id: CLIENT_EMPLOYEE_ID,
        Name: CLIENT_EMPLOYEE_NAME
    },
]




