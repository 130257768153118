import axios from 'axios';
import * as apiConst from "../taxmation-consts";

const DATEV_AUTH_CODE_URL = apiConst.DATEV_AUTH_CODE_URL;
export const getDatevAuthCodeUrl = () => {
    return axios
        .get(`${process.env.REACT_APP_URL + DATEV_AUTH_CODE_URL}`,
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}


