import * as apiConst from "../taxmation-consts";
import axios from "axios";


const GET_TASK_LIST = apiConst.GET_TASK_LIST;
export const getTaskList = (listData: object) => {
    return axios
        .post(`${process.env.REACT_APP_URL + GET_TASK_LIST}`,
            listData,
            {
                withCredentials: true,
            }
        )
        .then((response) => {
            return response.data;
            console.log(response.data);
        })
        .catch((error) => {
            return error.response.data
        })
}


const ADD_TASK = apiConst.ADD_TASK;
export const addTask = (task: {}) => {
    return axios
        .post(`${process.env.REACT_APP_URL + ADD_TASK}`, task,
            {
                withCredentials: true,
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}

const GET_TASK_INPUT_DATA = apiConst.GET_TASK_INPUT_DATA;
export const getTaskInputData = () => {
    return axios
        .get(`${process.env.REACT_APP_URL + GET_TASK_INPUT_DATA}`,
            {
                withCredentials: true,
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}

const GET_TASK = apiConst.GET_TASK;
export const getTaskById = (taskId: string) => {
    return axios
        .get(`${process.env.REACT_APP_URL + GET_TASK}?id=${taskId}`,
            {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                }
            },
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data;
        })
}

const UPDATE_TASK = apiConst.UPDATE_TASK;
export const updateTask = (taskData: object) => {
    return axios
        .put(`${process.env.REACT_APP_URL + UPDATE_TASK}`, taskData,
            {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data;
        })
}

const DELETE_TASK = apiConst.DELETE_TASK;
export const deleteTask = (taskId: string) => {
    return axios
        .delete(`${process.env.REACT_APP_URL + DELETE_TASK}?id=${taskId}`,
            {
                withCredentials: true,  // Include cookies
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}

const UPLOAD_TASK__DOCUMENT = apiConst.UPLOAD_TASK__DOCUMENT;
export const uploadTaskDocument = (taskId: string, files: File[]) => {
    const formData = new FormData();
    files.forEach((file) => {
        formData.append('files', file);
    });

    return axios
        .post(`${process.env.REACT_APP_URL + UPLOAD_TASK__DOCUMENT}?taskId=${taskId}`, formData,
            {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}

const DELETE_TASK_DOCUMENT = apiConst.DELETE_TASK_DOCUMENT;
export const deleteTaskDocument = (documetnId: string) => {
    return axios
        .delete(`${process.env.REACT_APP_URL + DELETE_TASK_DOCUMENT}?documetnId=${documetnId}`,
            {
                withCredentials: true,  // Include cookies
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}

const DUPLICATE_TASK= apiConst.DUPLICATE_TASK;
export const duplicateTask = (taskId: string, update:{}) => {
    return axios
        .post(`${process.env.REACT_APP_URL+ DUPLICATE_TASK}?taskId=${taskId}`,update,
            {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                }
            },
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data;
        })
}

const OPEN_OR_COMPLETE_TASK = apiConst.OPEN_OR_COMPLETE_TASK;
export const openOrCompleteTask = (taskId: string) => {
    return axios
        .get(`${process.env.REACT_APP_URL+ OPEN_OR_COMPLETE_TASK}?taskId=${taskId}`,
            {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                }
            },
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data;
        })
}