import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef, GridColType } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Grid, TextField } from '@mui/material';
import classes from "../../../assets/styles/Table.module.css";
import CheckIcon from '@mui/icons-material/Check';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import UpdateRole from './Dialogs/UpdateRole';
import * as userServices from "../../../services/user-services.proxy";
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getLoadingState, setLoading } from '../../../app/slices/loadingSlice';
import { getCallStatus, getErrorMsg, setCallStatus, setErrorMsg } from '../../../app/slices/apiCallSlice';
import DeleteDialog from '../../../sharedComponents/Dialogs/DeleteDialog';
import * as statusesConsts from "../../../status-consts";
import selectedFilter from "../../../assets/images/icons/selectedFilter.png";
import * as userRolesConst from "../../../user-roles-consts";
import { selectRoles, selectUserType } from '../../../app/slices/userDataSlice';
import {useIntl} from "react-intl";
import Message from '../../../sharedComponents/ui/Message';

const updateTMRole = userServices.updateTMRole;
const deleteUser = userServices.deleteUser;
export interface TableProps {
  rows: any;
  dataLength: any;
  setDataLength: any;
  roles: any;
  selectedRoles: any,
  setSelectedRoles: any,
  modify: any,
  setModify: any,
  paginationModel: any,
  setPaginationModel: any,
  // pageNb: any,
  // setPageNb: any,
  // pageSize: any,
  // setPageSize: any,
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: '0px 2px 4px 0px #0000001F',
    backgroundColor: '#FEFEFF',
    borderRadius: '10px',
    padding: 0,
  },
}));

const TeamMembersTable = (props: TableProps) => {

  const { rows, roles, selectedRoles, setSelectedRoles, modify, setModify, dataLength, paginationModel, setPaginationModel } = props;

  const dispatch = useAppDispatch();
  const intl = useIntl();

  const loading = useAppSelector(getLoadingState);
  const errorMsg = useAppSelector(getErrorMsg);
  const callStatus = useAppSelector(getCallStatus);
  const userRoles = useAppSelector(selectRoles);
  const userType = useAppSelector(selectUserType);

  const [filterIndex, setFilterIndex] = useState<number>();
  const [search, setSearch] = useState<string>();

  //update role 
  const [openUpdateRoleDialog, setOpenUpdateRoleDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [selectedUser, setSelectedUser] = useState<any>();
  const [selectedUserToDelete, setSelectedUserToDelete] = useState<any>();

  const TMRoles = roles?.filter((r: { typeId: number; }) => (localStorage.getItem('userType') ==='1'?r.typeId === 1:r.typeId === 2)) ;
  const [filteredRoles, setFilteredRoles]= useState<any>(TMRoles);
  const extractRolesIds = (rolesString: string) => {

    const roleNames = rolesString.split(',').map(name => name.trim());

    const ids = roles
      .filter((role: { name: string; }) => roleNames?.includes(role.name))
      .map((role: { id: any; }) => role.id);

    return ids;
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.toLowerCase();
    setSearch(searchValue);
    
    const filtered = TMRoles?.filter((role: any) => 
      role.name.toLowerCase()?.includes(searchValue)
    );
    setFilteredRoles(filtered);
  };

  const columns: GridColDef[] = [
    ...( (userType === 1 && userRoles?.includes(userRolesConst.ADMIN_ID)) || (userType === 2 && userRoles?.includes(userRolesConst.CLIENT_ADMIN_ID)) ? [{
      field: 'actions',
      type: 'actions' as GridColType,
      width: 10,
      sortable: false,
      renderCell: (params:any) => (
        <HtmlTooltip
          title={
            <React.Fragment>
              <Grid item container rowGap={1.5} padding={1.5} maxWidth='120px'>
                {/* {userRoles.includes(userRolesConst.SUPER_ADMIN_ID || userRolesConst.ADMIN_ID) ? */}
                <Grid item container direction='row' className='neutral-4 labels pointerText' alignItems='center' columnGap={1}
                  onClick={() => {
                    setSelectedUser(() => ({
                      userId: params.row.id,
                      email: params.row.email,
                      roles: extractRolesIds(params.row.roles),
                      type: 1,
                    }));
                    setOpenUpdateRoleDialog(true);
                  }}

                  >
                    <EditOutlinedIcon htmlColor='#605e6b' fontSize='small' /><Message id="ta.edit.edit-role" className="Field-input labelsLinks font-weight-400"/>
                  </Grid>
                  {/* :
                  <></>} */}


                {/* {userRoles.includes(userRolesConst.SUPER_ADMIN_ID) ? */}
                  <Grid item container direction='row' className='red labels pointerText' alignItems='center' columnGap={1}
                    onClick={() => {
                      setSelectedUserToDelete(() => ({
                        userId: params.row.id,
                        email: params.row.email,
                        roles: params.row.roles,
                        taskCount: params.row.taskCount
                      }));
                      setOpenDeleteDialog(true);
                    }}
                  ><DeleteOutlineIcon htmlColor='#EF4737' fontSize='small' /><Message id="ta.delete" className="Field-input labelsLinks font-weight-400"/></Grid>
                  {/* :
                  <></>
                } */}

              </Grid>
            </React.Fragment>
          }
        >
          <Grid item style={{ cursor: 'pointer' }}><MoreVertIcon htmlColor='#93919a' /></Grid>
        </HtmlTooltip>
      ),
    }] : []),
    { field: 'firstName', headerName: intl.formatMessage({ id: 'user.profile.first-name' }), width: 130 },
    { field: 'lastName', headerName: intl.formatMessage({ id: 'user.profile.last-name' }), width: 130 },
    { field: 'email', headerName: intl.formatMessage({ id: 'user.profile.email' }), width: 270 },
    {
      field: 'roles', headerName: intl.formatMessage({ id: 'user.user-role' }), width: 190,
      renderHeader(params: any) {
        return (
          <Grid item container className='table-header' alignItems='center' fontWeight='normal'><Message id="user.user-role" className="Field-input labels font-weight-400" />
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Grid item container rowGap={1} maxHeight='250px' padding={1} display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                    <Grid item>
                      <TextField
                        value={search}
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                          style: {
                            borderRadius: '35px',
                            fontFamily: 'DM Sans',
                            height: '37px',
                            backgroundColor: '#F7F9FF',
                            border: 0,
                            borderColor: '#E6E6E8',
                            fontSize: '12px'
                          },
                        }}
                        sx={{
                          '& .MuiInputBase-input::placeholder': {
                            fontWeight: 'bold',
                          },
                        }}
                        placeholder={intl.formatMessage({ id: 'text.search' })}
                        onChange={handleSearch}
                      /></Grid>
                    <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} marginLeft={1.5} style={{ cursor: selectedRoles.length > 0 ? 'pointer' : 'unset' }}
                      onClick={() => setSelectedRoles([])} ><RestartAltIcon fontSize='small' /> <Message id="ta.remove-filter" className="Field-input labels font-weight-400" /></Grid>
                    {filteredRoles?.map((val: any, index: number) => (
                      <Grid item container alignItems='center' key={val.id} columnGap={1} className={classes[filterIndex === index || selectedRoles?.includes(val.id) ? 'filter-selected' : 'filter-not-selected']} justifyContent='flex-start'
                        onMouseOver={(e) => {
                          const target = e.target as HTMLElement;
                          if (!selectedRoles?.includes(val.id)) {
                            target.style.cursor = 'pointer';
                            setFilterIndex(index);
                          }
                        }}
                        onMouseOut={() => {
                          setFilterIndex(-1);
                        }}
                        onClick={(e) => {
                          if (!selectedRoles?.includes(val.id)) {
                            setSelectedRoles((prevTenants: any) => [...prevTenants, val.id]);
                          }
                        }}
                      >
                        <Grid className='bordered-box' bgcolor={filterIndex === index || selectedRoles?.includes(val.id) ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={filterIndex === index || selectedRoles?.includes(val.id) ? '#00B69B' : '#9E9E9E'} /></Grid>
                        <Grid item>{val.name}</Grid>
                      </Grid>
                    ))}

                  </Grid>
                </React.Fragment>
              }
            >
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <ArrowDropDownIcon /> {selectedRoles.length > 0 ? <img src={selectedFilter} alt='icon' /> : <></>}
              </Grid>
            </HtmlTooltip>
          </Grid >
        )
      },
    },
    { field: 'lastActiveDate', headerName: intl.formatMessage({ id: 'user.last-active' }), width: 200 },
    {
      field: 'statusId', headerName: intl.formatMessage({ id: 'user.status' }), width: 170,
      renderCell: (params) => {
        return (
          <Grid item container direction='row' style={{ marginTop: '10px', height: '15px' }} columnGap={1.5}>
            <Grid item className={params.value === statusesConsts.ACTIVE_STATUS_ID ? 'active-status' :
              params.value === statusesConsts.IN_ACTIVE_STATUS_ID ? 'not-active-status' : params.value === statusesConsts.INVITED_STATUS_ID ? 'invited-status':''} width='70px'>
              {params.value === statusesConsts.ACTIVE_STATUS_ID ? 'Active' :
              params.value === statusesConsts.IN_ACTIVE_STATUS_ID ? 'In Active' : params.value === statusesConsts.INVITED_STATUS_ID ? 'Invited':''}
            </Grid>
          </Grid>
        );
      },
    },
    { field: 'phoneNumber', headerName: intl.formatMessage({ id: 'user.profile.phone-number' }), width: 200 },
    { field: 'primaryContactPerson', headerName: intl.formatMessage({ id: 'user.primary-contact-person' }), width: 200 },
    { field: 'country', headerName: intl.formatMessage({ id: 'user.country' }), width: 200 },
    // { field: 'position', headerName: 'Position', width: 200 },
    { field: 'creationDate', headerName: intl.formatMessage({ id: 'user.creation-date' }), width: 200 },
  ];

  const updateUserRoleCall = () => {
    dispatch(setLoading(true));
    updateTMRole({ userId: selectedUser.userId, roles: selectedUser.roles }).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus('Fail'));
        setTimeout(() => {
          dispatch(setErrorMsg(''));
          dispatch(setCallStatus(''))
        }, 4500)
      }
      else {
        dispatch(setCallStatus('Pass'));
        setTimeout(() => {
          dispatch(setErrorMsg(''));
          dispatch(setCallStatus(''));
          setOpenUpdateRoleDialog(false);
          dispatch(setModify(!modify))
        }, 1000)
      }
      dispatch(setLoading(false));
    })
  }

  const deleteUserCall = () => {
    dispatch(setLoading(true));
    deleteUser(selectedUserToDelete.userId).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus('Fail'));
        setTimeout(() => {
          dispatch(setErrorMsg(''));
          dispatch(setCallStatus(''))
        }, 4500)
      }
      else {
        dispatch(setCallStatus('Pass'));
        setTimeout(() => {
          dispatch(setErrorMsg(''));
          dispatch(setCallStatus(''));
          setOpenDeleteDialog(false);
          dispatch(setModify(!modify))
        }, 1000)
      }
      dispatch(setLoading(false));
    })
  }

  const handlePaginationModelChange = (model: any) => {
    // const adjustedPage = Math.max(1, model.page);
    // // Catch the new page number
    // setPageNb(adjustedPage);
    // setPageSize(model.pageSize);
    // Update the pagination model
    setPaginationModel(model);
  };

  return (
    <div style={{ height: '53vh', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pagination
        paginationMode='server'
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange} // Handle pagination changes
        rowCount={dataLength} // Total number of rows for server-side pagination
        pageSizeOptions={[5,10,25]}
        // checkboxSelection
        disableColumnMenu
        sx={{
          border: 2,
          borderColor: '#FEFEFF',
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
        }}
      />
      {selectedUser ?
        <UpdateRole
          openUpdateRoleDialog={openUpdateRoleDialog}
          setOpenUpdateRoleDialog={setOpenUpdateRoleDialog}
          user={selectedUser}
          setUser={setSelectedUser}
          roles={roles}
          confirmAction={updateUserRoleCall}
          loading={loading}
          callStatus={callStatus}
          errorMsg={errorMsg}
        />
        :
        <></>
      }

      {selectedUserToDelete ?
        <DeleteDialog
          openDeleteDialog={openDeleteDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
          loading={loading}
          callStatus={callStatus}
          errorMsg={errorMsg}
          object={selectedUserToDelete}
          confirmAction={deleteUserCall}
          type='team member'
        />
        :
        <></>
      }
    </div>
  )
}

export default TeamMembersTable