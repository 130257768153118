import React, { useEffect, useState } from 'react';
import classes from "../assets/styles/Login.module.css";
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { updateToken } from '../app/slices/userDataSlice'
import { Grid } from '@mui/material';
import Logo from "../assets/images/logoWhite.png"
import LoginContainer from '../components/Login/LoginContainer';
import '../App.css';
import Message from '../sharedComponents/ui/Message';
import * as authServices from "../services/auth-services.proxy";


const testAPI = authServices.testApi;

const LoginPage = ( {loginFormState, setLoginFormState}:{loginFormState:any,setLoginFormState:any}
  //{ setIsLoggedIn, setIsCMS, loginFormState, setLoginFormState, isSSO, setIsSSO }: { setIsLoggedIn: any, setIsCMS: any, loginFormState: any, setLoginFormState: any, isSSO :any, setIsSSO:any }
) => {

  const token = useAppSelector((state) => state.userData.token)
  const dispatch = useAppDispatch()

  // useEffect(() => {
  //   if (!localStorage.getItem('loginFormState')) localStorage.setItem('loginFormState', 'default')
  // }, [loginFormState])

  // const testCall = () => {
  //   testAPI().then((x) => {
  //   })
  // }

  return (
    <div className={classes["loginBg"]}>
      <Grid item container direction="row" justifyContent='center' paddingTop='15vh' columnGap={0.5}>
        {/* <Grid item>
          <button onClick={() => testCall()}>test api</button>
        </Grid> */}
        <Grid item container direction="column" xs={4} marginTop='5%' textAlign='left'>
          <Grid item> <img src={Logo} alt='logo' width={430} /></Grid>
          <Grid item className='offwhite-50 desktop-header-4' paddingTop="10%">
            <Message
              id="home.hero-title"
              className='offwhite-50 desktop-header-4'
            />
          </Grid>
          <Grid item className='offwhite-50 labels' width="380px" paddingTop="5%">
            <Message
              id="home.description"
              clasName='offwhite-50 labels'
            />
          </Grid>
        </Grid>
        <LoginContainer loginFormState={loginFormState} setLoginFormState={setLoginFormState}
        // setIsLoggedIn={setIsLoggedIn} setIsCMS={setIsCMS} isSSO={isSSO} setIsSSO={setIsSSO}  
         />
      </Grid>
    </div>
  )
}

export default LoginPage