import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

interface apiCallState {
    errorMsg: string,
    status: string,
    actionCallFrom: string,
}

const initialState: apiCallState = {
    errorMsg: '',
    status: '',
    actionCallFrom: '',
}

export const apiCallSlice = createSlice({
    name: 'apiCall',
    initialState,
    reducers: {
        setErrorMsg: (state, action: PayloadAction<string>) => {
            state.errorMsg = action.payload
        },
        setCallStatus: (state, action: PayloadAction<string>) => {
            state.status = action.payload
        },
        setActionCallFrom: (state, action: PayloadAction<string>) => {
            state.actionCallFrom = action.payload
        }
    },
})

export const errorActions = apiCallSlice.actions

export const {setErrorMsg, setCallStatus, setActionCallFrom } =apiCallSlice.actions;

export const getErrorMsg = (state: RootState) => state.apiCall.errorMsg

export const getCallStatus = (state: RootState) => state.apiCall.status

export const getActionCallFrom = (state: RootState) => state.apiCall.actionCallFrom

export default apiCallSlice.reducer