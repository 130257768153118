import * as apiConst from "../taxmation-consts";
import axios from "axios";

const GENERATE_QR = apiConst.GENERATE_QR
export const generateQR = () => {
    return axios
        .post(`${process.env.REACT_APP_URL + GENERATE_QR}`,
            {
                withCredentials: true,  // Include cookies
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}

const VERIFY_OTP = apiConst.VERIFY_OTP;
export const verifyAndValidate = (otp : string) => {
    return axios
        .post(`${process.env.REACT_APP_URL + VERIFY_OTP}?otp=${otp}`,
            {
                withCredentials: true,  // Include cookies
                headers: {
                    'Content-Type': 'application/json',
                 
                }
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}

const VALIDATE_OTP = apiConst.VALIDATE_OTP;
export const validateOTP = (otp:any) => {
    return axios
        .post(`${process.env.REACT_APP_URL + VALIDATE_OTP}?otp=${otp}`,
            {
                withCredentials: true,  // Include cookies
                headers: {
                    'Content-Type': 'application/json',
                 
                }
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}

const DISABLE_OTP = apiConst.DISABLE_OTP;
export const disableOTP = () => {
    return axios
        .post(`${process.env.REACT_APP_URL + VERIFY_OTP}`
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}

