import React, { useState, useEffect } from 'react';
import { Backdrop, CircularProgress, Grid } from '@mui/material';
import * as otpServices from "../services/otp-services.proxy";
import { setLoading, getLoadingState, getDialogLoadingState, setDialogLoading } from '../app/slices/loadingSlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { setErrorMsg, getErrorMsg, getCallStatus, setCallStatus } from '../app/slices/apiCallSlice';
import QRCode from 'qrcode';
import { selectIsOtpEnabled, selectIsOtpVerified, selectRoles, selectUserType, setIsCMS, setIsOtpEnabled, setIsOtpVerified } from '../app/slices/userDataSlice';
import { useNavigate } from 'react-router-dom';
import { setIsLoggedIn } from '../app/slices/userDataSlice';
import Message from '../sharedComponents/ui/Message';
import OTPInput from '../sharedComponents/ui/OTPInput';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CheckIcon from '@mui/icons-material/Check';
import * as userRolesConst from "../user-roles-consts";

const generateQR = otpServices.generateQR;
const verifyAndValidate = otpServices.verifyAndValidate;
const validateOTP = otpServices.validateOTP;

export interface TwoFactorAuthProps {
  setLoginFormState: any;
}

const TwoFactorAuthentication = (props: TwoFactorAuthProps) => {
  const { setLoginFormState } = props;
  const [otp, setOtp] = useState('');
  const [qrCodeUrl, setqrCodeUrl] = useState('');
  const [secret, setSecret] = useState({
    otpauth_url: '',
    base32: '',
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const loading = useAppSelector(getLoadingState);
  const dialogLoading = useAppSelector(getDialogLoadingState);
  const errorMsg = useAppSelector(getErrorMsg);
  const callStatus = useAppSelector(getCallStatus);
  const otp_enabled = useAppSelector(selectIsOtpEnabled);
  const otp_verified = useAppSelector(selectIsOtpVerified);
  const userType = useAppSelector(selectUserType);
  const userRoles = useAppSelector(selectRoles);


  useEffect(() => {
    if (localStorage.getItem('isOtpEnabled') === 'false') {
      generateQRCall();
    }
  }, [otp_enabled]);

  useEffect(() => {
    if (localStorage.getItem('isOtpEnabled') === 'false') dispatch(setIsOtpEnabled(false));
    else dispatch(setIsOtpEnabled(true));

    if (localStorage.getItem('isOtpVerified') === 'false') dispatch(setIsOtpVerified(false));
    else dispatch(setIsOtpVerified(true));
  });

  const generateQRCall = () => {
    dispatch(setDialogLoading(true));
    generateQR().then((x) => {
      setSecret({
        base32: x.base32,
        otpauth_url: x.otpauth_url,
      });
      QRCode.toDataURL(x.otpauth_url, (err, url) => {
        if (err) console.log(err);
        setqrCodeUrl(url);
      });
      dispatch(setDialogLoading(false));
    });
  };

  const verifyAndValidateCall = () => {
    dispatch(setLoading(true));

    verifyAndValidate(otp).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus('Fail'));
      } else {
        if (x.otpEnabled === true) {
          dispatch(setIsOtpEnabled(true));
          localStorage.setItem('isOtpEnabled', 'true');
          dispatch(setIsOtpVerified(true));
          localStorage.setItem("isOtpVerified", 'true')
          dispatch(setCallStatus('Pass'))
          if (localStorage.getItem('isSubscribed') === 'false')
            navigate('/plan-selection')
          else {
            if ((userType === 2 && !userRoles?.includes(userRolesConst.CLIENT_ADMIN_ID))) navigate('/tasks')
            else navigate('/users')
          }
        }
        else {
          dispatch(setErrorMsg('Otp is invalid'));
          dispatch(setCallStatus('Fail'));
        }
      }

      setTimeout(() => {
        dispatch(setErrorMsg(''));
        dispatch(setCallStatus(''));
      }, 4500);

      dispatch(setLoading(false));
    });
  };

  const validateOTPCall = () => {
    dispatch(setLoading(true));

    validateOTP(otp).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus('Fail'));
      } else {
        if (x === true) {
          dispatch(setIsOtpVerified(true));
          localStorage.setItem("isOtpVerified", 'true')
          dispatch(setCallStatus('Pass'))
          if (localStorage.getItem('isSubscribed') === 'false')
            navigate('/plan-selection')
          else {
            if ((userType === 2 && !userRoles?.includes(userRolesConst.CLIENT_ADMIN_ID))) navigate('/tasks')
            else navigate('/users')
          }
        }
        else {
          console.log("false")
          dispatch(setErrorMsg('Otp is invalid'));
          dispatch(setCallStatus('Fail'));
        }
      }

      setTimeout(() => {
        dispatch(setErrorMsg(''));
        dispatch(setCallStatus(''));
      }, 4500);

      dispatch(setLoading(false));
    });
  };

  return (
    <Grid item container justifyContent="center" direction="column" alignItems="center" bgcolor="#f3f4f8" height="100vh">
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={dialogLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {otp_enabled && !otp_verified ? (
        <>
          <Grid item className="primaryblue-700 labels-extra-bold desktop-header-1">
            <Message id="login.welcome-back" className="primaryblue-700 labels-extra-bold desktop-header-1" />
          </Grid>
          <Grid item className="primaryblue-700 desktop-header-6 font-weight-400">
            <Message id="auth.verify-authentication-code" className="primaryblue-700 desktop-header-6 font-weight-400" />
          </Grid>

          <Grid item container direction="column" padding={4} rowGap={2.5} className="dialogContainer" width="35vw" alignItems="center" marginTop={2}>
            <Grid item className="labels-extra-bold desktop-header-5 primaryblue-800" textAlign="center">
              <Message id="auth.two-factor" className="labels-extra-bold desktop-header-5 primaryblue-800" />
              <Message id="auth.authentication" className="labels-extra-bold desktop-header-5 primaryblue-800" />
            </Grid>

            <Grid item textAlign="center" className="primaryblue-800 desktop-header-6">
              <Message id="auth.get-verifaction-code" className="primaryblue-800 desktop-header-6" />
            </Grid>

            <Grid item width="100%">
              {/* OTPInput for entering OTP */}
              <OTPInput
                numInputs={6}
                value={otp}
                onChange={setOtp}
                shouldAutoFocus
                isNumericString
                isInvalid={callStatus === 'Fail'}
                margin="0 auto"
              />
            </Grid>
            {errorMsg ?
              <Grid item className='errorText'>{errorMsg}</Grid>
              :
              <></>}
            <Grid item width='100%'>
              <button
                className={callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'blueButton'}
                disabled={loading || !otp}
                onClick={() => validateOTPCall()}
              >
                {loading ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                  : callStatus === 'Pass' ? <span><CheckCircleOutlinedIcon style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                    : callStatus === 'Fail' ? <Message id="text.failed" className='Field-input labels-extra-bold labelsLinks' />
                      : <Message id="auth.authenticate" />}
              </button>
            </Grid>
            <Grid
              item
              className="iris-100 desktop-header-6"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                localStorage.clear();
                dispatch(setIsLoggedIn(false));
                dispatch(setIsCMS(false));
                localStorage.setItem('loginFormState', 'default');
                setLoginFormState('default');
                navigate('/');
              }}
            >
              <Message id="text.back-to-login" />
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid item container direction="column" padding={4} rowGap={2} className="dialogContainer" xs={5} width="45vw">
          <Grid item className="labels-extra-bold desktop-header-5">
            <Message id="auth.two-factor-authentication" className="labels-extra-bold desktop-header-5" />
          </Grid>

          <Grid item container direction="column" rowGap={1} className="desktop-paragraph primaryblue-900">
            <Grid item className="iris-100 labels-extra-bold ">
              <Message id="auth.configuring-app" className="iris-100 labels-extra-bold" />
            </Grid>
          </Grid>

          <Grid item container direction="column" className="desktop-paragraph primaryblue-900">
            <Grid item>
              <Message id="auth.install-app" className="Field-input font-weight-400 neutral-6" />
            </Grid>
            <Grid item>
              <Message id="auth.select-plus-icon" className="Field-input font-weight-400 neutral-6" />
            </Grid>
            <Grid item>
              <Message id="auth.select-scan-barcode" className="Field-input font-weight-400 neutral-6" />
            </Grid>
          </Grid>

          <Grid item container rowGap={1} className='desktop-paragraph primaryblue-900' direction='column'>
            <Grid item className='iris-100 labels-extra-bold '><Message id="auth.scan-qrcode" className='iris-100 labels-extra-bold ' /> </Grid>
            <img
              src={qrCodeUrl}
              alt="qrcode url"
              width='160px'
              height='160px'
            />
          </Grid>

          <Grid item container direction="column" rowGap={1.5} className="desktop-paragraph primaryblue-900">
            <Grid item className='iris-100 labels-extra-bold '><Message id="auth.verify-code" className='iris-100 labels-extra-bold ' /> </Grid>
            <Message id="auth.enable-two-factor-authentication" className='Field-input font-weight-400 neutral-6' />
            <OTPInput
              numInputs={6}
              value={otp}
              onChange={setOtp}
              shouldAutoFocus
              isNumericString
              isInvalid={callStatus === 'Fail'}
              margin='0'
            />

          </Grid>
          <Grid item container direction='row' columnGap={1.5} justifyContent='flex-end' alignItems='center' rowGap={1}>
            {errorMsg ?
              <Grid item className='errorText' xs={12}>{errorMsg}</Grid>
              :
              <></>}
            <Grid item xs={3}>
              <button className='greyButton'
                onClick={() => {
                  localStorage.clear();
                  dispatch(setIsLoggedIn(false));
                  dispatch(setIsCMS(false));
                  localStorage.setItem('loginFormState', 'default')
                  navigate('/');
                }}>
                <Message id="button.close" className='Field-input labels-extra-bold labelsLinks' />
              </button></Grid>
            <Grid item xs={3}>
              <button
                className={callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'blueButton'}
                disabled={loading || !otp}
                onClick={() => verifyAndValidateCall()}>
                {loading ?
                  <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                  : callStatus === 'Pass' ? <span><CheckCircleOutlinedIcon style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                    : callStatus === 'Fail' ? <Message id="text.failed" className='Field-input labels-extra-bold labelsLinks' />
                      : <Message id="auth.verify-activate" className='Field-input labels-extra-bold labelsLinks' />}

              </button></Grid>

          </Grid>
        </Grid>)
      }
    </Grid>
  );
};

export default TwoFactorAuthentication;
