import React, { useState, useEffect } from 'react'
import { Box, Grid, MenuItem, TextField } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Message from '../../../sharedComponents/ui/Message';
import classes from "../../../assets/styles/Task.module.css";
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import historyIcon from "../../../assets/images/icons/history.png";
import Messages from './Messages';
import History from './History';
import * as TaskServices from '../../../services/task-services.proxy';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getActionCallFrom, getCallStatus, getErrorMsg, setActionCallFrom, setCallStatus, setErrorMsg } from '../../../app/slices/apiCallSlice';
import { getLoadingState, setDialogLoading, setLoading } from '../../../app/slices/loadingSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import moment from 'moment';
import 'moment/locale/de';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Attachments from './Attachments';
import ProfileImage from '../../../sharedComponents/ui/ProfileImage';
import * as StatusesConsts from "../../../status-consts";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { selectRoles, selectUserType } from '../../../app/slices/userDataSlice';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import * as userServices from "../../../services/user-services.proxy";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteDialog from '../../../sharedComponents/Dialogs/DeleteDialog';
import * as userRolesConst from "../../../user-roles-consts";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';


const getUserProfile = userServices.getUserProfile;

export interface TaskProps {
  taskDetails: any;
  typesList: any;
  assigneesList: any;
  clientsList: any;
  statusesList: any;
  moduleList: any;
  historyCategoriesList: any;
  updateTaskCall: any;
  addCommentCall: any;
  commentLoading: any;
  commentTxt: any;
  setCommentTxt: any;
  handleFileChange: any;
  fileLoading: any;
  deleteTaskDocumentCall: any;
  uploadTaskDocumentCall: any;
  files: any;
  recurringPeriodsList: any;
  internalAssigneesList: any;
  externalAssigneesList: any;
  isFocused: any;
  setIsFocused: any;
  moveDocumentToDatevCall: any;
  movingToDatevLoading: any;
  taskObject: any;
  setTaskObject: any;
  setOpen: any
  open: any;
  setOpenDeleteDialog:any;
  setselectedTaskToDelete: any;
  openOrCompleteTaskCall: any;
  commentFiles: any;
  setCommentFiles: any;

}

const TaskDetailsContainer = (props: TaskProps) => {



  const {
    taskDetails,
    typesList,
    assigneesList,
    clientsList,
    statusesList,
    moduleList,
    historyCategoriesList,
    updateTaskCall,
    addCommentCall,
    commentLoading,
    commentTxt,
    setCommentTxt,
    handleFileChange,
    fileLoading,
    deleteTaskDocumentCall,
    uploadTaskDocumentCall,
    files,
    recurringPeriodsList,
    internalAssigneesList,
    externalAssigneesList,
    isFocused,
    setIsFocused,
    moveDocumentToDatevCall,
    movingToDatevLoading,
    taskObject,
    setTaskObject,
    setOpen , 
    open,
    setOpenDeleteDialog,
    setselectedTaskToDelete,
    openOrCompleteTaskCall,
    commentFiles,
    setCommentFiles
  } = props


  const dispatch = useAppDispatch();

  const [activeTab, setActiveTab] = useState('Messages');
  const [iseRecuringTask, setIsRecuringTask] = useState(taskDetails?.recurringPeriod === 0 ? false : true);
  const prioritiesList = [1, 2, 3, 4, 5];



  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#65C466',
          opacity: 1,
          border: 0,
          ...theme.applyStyles('dark', {
            backgroundColor: '#2ECA45',
          }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
        ...theme.applyStyles('dark', {
          color: theme.palette.grey[600],
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
        ...theme.applyStyles('dark', {
          opacity: 0.3,
        }),
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
      ...theme.applyStyles('dark', {
        backgroundColor: '#39393D',
      }),
    },
  }));

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      boxShadow: '0px 2px 4px 0px #0000001F',
      backgroundColor: '#FEFEFF',
      borderRadius: '10px',
      padding: 0,
      color: 'black',
    },
  }));


  const navigate = useNavigate();
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => {
    // Updating state with an empty object to trigger a re-render
    updateState({});
  }, []);

  const loading = useAppSelector(getLoadingState);
  const errorMsg = useAppSelector(getErrorMsg)
  const callStatus = useAppSelector(getCallStatus);
  const userType = useAppSelector(selectUserType);
  const userRoles = useAppSelector(selectRoles);
  const actionCallFrom = useAppSelector(getActionCallFrom);
  const token = useAppSelector((state) => state.userData.token);

  const [openAssigneesDropdown, setOpenAssigneesDropdown] = useState(false);
  const [assigneesType, setAssigneesType] = useState('Internal');
  const [username, setUsername] = useState('');

  useEffect (() => {
    console.log("actionCallFrom",actionCallFrom)
  },[actionCallFrom])

  const getUserProfileCall = () => {
    getUserProfile(token).then((x) => {
      setUsername(x.username);
    })
  }


  useEffect(() => {
    getUserProfileCall();
  }, [username]);

  // //Files
  // const [file, setFile] = useState(null);
  // const [files, setFiles] = useState<File[]>([]);
  // const [fileId, setFileId] = useState(null);
  // const [fileIds, setFileIds] = useState([]);

  const [dueDateError, setDueDateError] = useState(false);
  // const files = [
  //   {
  //     id: 1,
  //     name: "File Name 1",
  //     size: '14KB',
  //     date: "5/9/2024"
  //   },
  //   {
  //     id: 2,
  //     name: "File Name 2",
  //     size: '14KB',
  //     date: "5/9/2024"
  //   },

  // ]

  // useEffect(() => {
  //   if (file) {
  //     let tempFile = null;
  //     tempFile = file;
  //     files.push(tempFile);
  //   }
  // }, [file]);

  // useEffect(() => {
  //   if (fileId) {
  //     let tempFileId = null;
  //     tempFileId = fileId;
  //     let fileData = { FileId: tempFileId };
  //     fileIds.push(fileData);
  //     // if (mrId) MRUpperDataUpdate();
  //   }
  // }, [fileId]);

  // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const uploadedFiles = event.target.files;
  //   if (uploadedFiles) {
  //     // Handle the file upload logic here
  //     const filesArray = Array.from(uploadedFiles);
  //     setFiles(filesArray);
  //   }
  // };

  // const handleDateChange = (newValue: any) => {
  //   if (newValue && newValue.isValid()) {
  //     taskDetails.dueDate = newValue.format('YYYY-MM-DD');
  //     setDueDateError(false);
  //   } else {
  //     setDueDateError(true); // Trigger error state if the date is invalid
  //   }
  // };

  const handleDateChange = (newValue: any) => {
    if (newValue && newValue.isValid()) {
      taskDetails.dueDate = moment(newValue).format('YYYY-MM-DD');
      setDueDateError(false);
      if (taskDetails.status === StatusesConsts.OVERDUE_STATUS_ID) {
        taskDetails.status = StatusesConsts.IN_PROGRESS_STATUS_ID
        forceUpdate();
      }
    } else {
      setDueDateError(true); // Trigger error state if the date is invalid
    }
  };


  useEffect(() => {
    console.log(taskDetails.dueDate);
    console.log(dueDateError);
    console.log("exeternal", externalAssigneesList)
    console.log("clientlist in cn", clientsList)

  }, [taskDetails.dueDate, dueDateError])

  const handleRecuringChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsRecuringTask(event.target.checked);
  };

  return (
    <>
      {
        taskDetails ?
          <Grid item container direction='row' justifyContent='space-between'>
            <Grid item container direction='column' xs={7.5} rowGap={2}>
              <Grid item className='black desktop-header-5'>
                {/* {taskDetails?.title} */}
                <TextField
                  fullWidth
                  value={taskDetails?.title}
                  disabled={taskDetails.status === StatusesConsts.CLOSED_STATUS_ID}
                  onChange={(e) => {
                    taskDetails.title = e.target.value
                    forceUpdate();
                  }}
                  sx={{
                    '& label.Mui-focused': {
                      color: '#C3C2C7',
                    },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#B2BAC2',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#C3C2C7',
                      },
                      '&:hover fieldset': {
                        borderColor: '#B2BAC2',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#6F7E8C',
                      },
                    },
                  }}
                  InputProps={{ className: 'fieldDesign' }}
                />
              </Grid>
              <Grid item columnGap={1} container direction='row' className='neutral-3 bold' alignItems='center'>
                <HomeOutlinedIcon style={{ color: '#93919A' }} fontSize='small' /> <Message id="user.profile.home" className="Field-input desktop-paragraph neutral-3 bold" /> / <Message id="task.tasks" className="Field-input desktop-paragraph neutral-3 bold" />
                {/* / <Message id="task.task-id" className="Field-input desktop-paragraph neutral-3 bold" /> */}
              </Grid>
              {/* <Grid item container rowGap={0.5} width='90%'>
          <span className='black labels-extra-bold'>Description</span>
          {taskDetails.title}
        </Grid> */}
              <Grid item container direction='column'>
                <Grid item className='black font-14 labels-extra-bold' ><Message id="task.description" className='black font-14 labels-extra-bold' /></Grid>
                <Grid item >
                  {/* {taskDetails?.title} */}
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    value={taskDetails?.description}
                    disabled={taskDetails.status === StatusesConsts.CLOSED_STATUS_ID}
                    onChange={(e) => {
                      taskDetails.description = e.target.value
                      forceUpdate();
                    }}
                    sx={{
                      '& label.Mui-focused': {
                        color: '#C3C2C7',
                      },
                      '& .MuiInput-underline:after': {
                        borderBottomColor: '#B2BAC2',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#C3C2C7',
                        },
                        '&:hover fieldset': {
                          borderColor: '#B2BAC2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#6F7E8C',
                        },
                      },
                    }}
                    InputProps={{ className: 'descriptionDesign' }}
                    inputProps={{ maxLength: 10000 }}
                  />
                </Grid>
              </Grid>

              <Grid item container rowGap={2}>
                <Grid item container direction='row' columnGap={4}>
                  <Grid item className='black font-14 labels-extra-bold' xs={1}><Message id="task.type" className="Field-input font-weight-700 font-14" /></Grid>
                  <Grid item>
                    {/* {taskDetails?.type} */}
                    {typesList?.find((i: { id: any; }) => i.id === taskDetails?.type)?.name}
                  </Grid>

                </Grid>
                <Grid item container direction='row' columnGap={4}>
                  <Grid item className='black font-14 labels-extra-bold' xs={1}><Message id="task.client" className="Field-input font-weight-700 font-14" /></Grid>
                  <Grid item className='labels'> {clientsList?.find((i: { id: any; }) => i.id === taskDetails?.clientId)?.name}
                  </Grid>
                </Grid>
                <Grid item container direction='row' columnGap={4}>
                  <Grid item className='black font-14 labels-extra-bold' xs={1}><Message id="task.module" className="Field-input font-weight-700 font-14" /></Grid>
                  <Grid item className='labels'>{moduleList?.find((i: any) => i.id === taskDetails.module)?.name}</Grid>
                </Grid>
              </Grid>
              <Grid item container className='whiteContainer'>
                <Attachments taskDetails={taskDetails} handleFileChange={handleFileChange} files={files} fileLoading={fileLoading} deleteTaskDocumentCall={deleteTaskDocumentCall} uploadTaskDocumentCall={uploadTaskDocumentCall} />

              </Grid>
              <Grid item container className={classes['CommentContainer']}>
                <Grid item container direction='row'>
                  <Grid item container width='181px' columnGap={1} alignItems='center' padding='15px'
                    //  className='black font-14 labels-extra-bold' 
                    className={classes[activeTab === 'Messages' ? 'messagesTabActive' : '']}
                    style={{ cursor: activeTab === 'Messages' ? 'unset' : 'pointer' }}
                    onClick={() => {
                      setActiveTab('Messages')
                    }}
                  > <ChatOutlinedIcon htmlColor='#93919a' /> <Message id="task.messages" className="Field-input font-14" />    <span className='nbCircle'>{taskDetails?.comments?.length}</span> </Grid>
                  <Grid item container width='180px'
                    style={{ cursor: activeTab === 'History' ? 'unset' : 'pointer' }}
                    columnGap={1} alignItems='center' padding='15px' className={classes[activeTab === 'History' ? 'historyTabActive' : '']}
                    onClick={() => {
                      setActiveTab('History')
                    }}
                  >
                    <img src={historyIcon} alt='icon' /><Message id="task.history" className="Field-input font-14" /> </Grid>
                </Grid>
                <Grid item container padding={2}>
                  {activeTab === 'Messages' ?
                    <Messages
                      taskDetails={taskDetails}
                      addCommentCall={addCommentCall}
                      moveDocumentToDatevCall={moveDocumentToDatevCall}
                      movingToDatevLoading={movingToDatevLoading}
                      commentLoading={commentLoading}
                      commentTxt={commentTxt}
                      commentFiles={commentFiles}
                      setCommentFiles={setCommentFiles}
                      setCommentTxt={setCommentTxt}
                      internalAssigneesList={internalAssigneesList}
                      externalAssigneesList={externalAssigneesList}
                      isFocused={isFocused}
                      setIsFocused={setIsFocused}
                      username={username}
                    />

                    :
                    <History taskDetails={taskDetails} historyCategoriesList={historyCategoriesList} />}
                </Grid>

              </Grid>
              <Grid item container direction='row' justifyContent='flex-end' marginTop={3} marginBottom={2} columnGap={1}>
                <Grid item xs={3}
                  onClick={() => {
                    if (localStorage.getItem('isCMS') === 'true') navigate('/admin-panel/users')
                    else navigate('/tasks')
                  }}>
                  <button className='greyButton'><Message id="button.cancel" className='labels-extra-bold Field-input labelsLinks' /></button>
                </Grid>
                <Grid item xs={3}>
                  <button
                    className={actionCallFrom === 'Apply'? callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'blueButton': 'blueButton'}
                    disabled={loading || !taskDetails?.title || taskDetails.status === StatusesConsts.CLOSED_STATUS_ID}
                    onClick={() => { 
                      dispatch(setActionCallFrom('Apply'))
                      updateTaskCall() 
                      }}>
                    {loading && actionCallFrom === 'Apply'? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                      : callStatus === 'Pass' && actionCallFrom === 'Apply' ? <span><CheckCircleOutlinedIcon style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                        : callStatus === 'Fail' && actionCallFrom === 'Apply'? "Failed"
                          : <Message id="button.apply" className='labels-extra-bold Field-input labelsLinks' />}
                  </button>
                  <Grid item textAlign='center' className='errorText' paddingTop={1}>{errorMsg}</Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={4} className='whiteContainer' height={600} rowGap={3} justifyContent='center' alignItems='center'>
              <Grid item xs={6}>
                <span className='labels-extra-bold'><Message id="user.status" className='Field-input font-weight-700 font-14' /></span>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  value={taskDetails?.status}
                  disabled={taskDetails.status === StatusesConsts.CLOSED_STATUS_ID}
                  select
                  onChange={(e) => {
                    taskDetails.status = e.target.value
                    forceUpdate();
                  }}

                  sx={{
                    '& label.Mui-focused': {
                      color: '#00B69B',
                    },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#B2BAC2',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                      },
                    },
                  }}
                  InputProps={{ className: taskDetails.status === StatusesConsts.NEW_STATUS_ID ? 'statusFieldDesign' : taskDetails.status === StatusesConsts.OPEN_STATUS_ID ? 'OpenStatusFieldDesign' : taskDetails.status === StatusesConsts.OVERDUE_STATUS_ID ? 'OverdueStatusFieldDesign' : taskDetails.status === StatusesConsts.IN_PROGRESS_STATUS_ID ? 'InProgressStatusFieldDesign' : taskDetails.status === StatusesConsts.CLOSED_STATUS_ID ? 'ClosedStatusFieldDesign' : taskDetails.status === StatusesConsts.WAITING_FOR_OTHERS_STATUS_ID ? 'WaitingForOthersStatusFieldDesign' : taskDetails.status === StatusesConsts.UPCOMING_STATUS_ID ? 'UpcomingStatusFieldDesign' : '' }}
                >
                  {statusesList?.map((option: any) => (
                    <MenuItem key={option.id} value={option.id} disabled={option.id !== StatusesConsts.IN_PROGRESS_STATUS_ID && option.id !== StatusesConsts.WAITING_FOR_OTHERS_STATUS_ID}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>

              </Grid>
              <Grid item xs={4}>
                <span className='labels-extra-bold'><Message id="task.assignee" className='Field-input font-weight-700 font-14' /></span>
              </Grid>
              <Grid item xs={2} textAlign='right' paddingRight={1}><AccountCircleIcon style={{ width: '40px', height: '40px' }} /></Grid>

              <Grid item xs={6}>
                <HtmlTooltip
                  open={openAssigneesDropdown}
                  title={
                    <React.Fragment>
                      <Grid item container maxHeight='160px' padding={1} display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                        <Grid item container direction='row' columnGap={2} marginBottom={1.5} justifyContent='center'>
                          <Grid item className={classes[assigneesType === 'Internal' ? "internal-external-active" : "internal-external-button"]}
                            onClick={() => setAssigneesType('Internal')}>Internal</Grid>
                          <Grid item className={classes[assigneesType === 'External' ? "internal-external-active" : "internal-external-button"]}
                            onClick={() => setAssigneesType('External')}>External</Grid>
                        </Grid>
                        <Grid item container direction="column" rowGap={1}>
                          {assigneesType === 'Internal' ? internalAssigneesList?.map((i: any, index: any) => (
                            <Grid item container alignItems='center' key={i.id} columnGap={1} className={classes[i.id === taskDetails.assigneeId ? 'filter-selected' : 'filter-not-selected']} justifyContent='space-between'
                              onMouseOver={(e) => {
                                const target = e.target as HTMLElement;
                                target.style.cursor = 'pointer';
                              }}
                              onClick={(e) => {
                                taskDetails.assigneeId = i.id
                                forceUpdate();
                                setOpenAssigneesDropdown(false);
                              }}
                            >
                              <Grid item>{i.name}</Grid>
                              <Grid className='bordered-box' bgcolor={i.id === taskDetails?.assigneeId ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={i.id === taskDetails?.assigneeId ? '#00B69B' : '#9E9E9E'} /></Grid>
                            </Grid>
                          )) :
                            externalAssigneesList?.map((i: any) => (
                              <Grid item container alignItems='center' justifyContent='space-between' key={i.id} columnGap={1} className={classes[i.id === taskDetails?.assigneeId ? 'filter-selected' : 'filter-not-selected']}
                                onMouseOver={(e) => {
                                  const target = e.target as HTMLElement;
                                  target.style.cursor = 'pointer';
                                }}
                                onClick={(e) => {
                                  taskDetails.assigneeId = i.id
                                  forceUpdate();
                                  setOpenAssigneesDropdown(false);
                                }}
                              >
                                <Grid item>{i.name}</Grid>
                                <Grid className='bordered-box' bgcolor={i.id === taskDetails?.assigneeId ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={i.id === taskDetails?.assigneeId ? '#00B69B' : '#9E9E9E'} /></Grid>
                              </Grid>
                            ))}
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  }
                >

                  <Grid item container justifyContent='space-between'
                    alignItems='center' textAlign='center' width='100%'
                    className='fieldDesign' style={{ border: 'solid 1px #C3C2C7', cursor: taskDetails.status !== StatusesConsts.CLOSED_STATUS_ID ? 'pointer' : 'unset' }}
                    paddingRight={0.8}
                    paddingLeft={1.5}
                    direction='row'
                    onClick={() => {
                      if (taskDetails.status !== StatusesConsts.CLOSED_STATUS_ID)
                        setOpenAssigneesDropdown(!openAssigneesDropdown)
                    }}>
                    <Grid item style={{ flexGrow: 1, maxWidth: 160, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{internalAssigneesList?.find((i: any) => i.id === taskDetails?.assigneeId)?.name} {externalAssigneesList?.find((i: any) => i.id === taskDetails?.assigneeId)?.name}</Grid>
                    <Grid item textAlign='right' marginTop={1}><ArrowDropDownIcon htmlColor='#6F7E8C' /></Grid>
                  </Grid>

                </HtmlTooltip>
                {/* <Box display="flex" alignItems="center">
                  <AccountCircleIcon style={{ width: '40px', height: '40px' }} />
                  <Grid item width='100%' xs={9}>
                    <TextField
                      fullWidth
                      select
                      value={taskDetails?.assigneeId}
                      defaultValue={taskDetails?.assigneeId}
                      onChange={(e) => {
                        taskDetails.assigneeId = e.target.value
                        forceUpdate();
                      }}
                      sx={{
                        '& label.Mui-focused': {
                          color: 'white',
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: 'white',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'white',
                          },
                          '&:hover fieldset': {
                            borderColor: 'white',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'white  ',
                          },
                        },
                      }}
                      InputProps={{ className: 'fieldDesign' }}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: 150, // Set the max height of the dropdown
                            },
                          },
                        },
                      }}
                    >
                      {assigneesList?.map((option: any) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Box> */}
              </Grid>
              <Grid item xs={6}>
                <span className='labels-extra-bold'><Message id="task.due-date" className='Field-input font-weight-700 font-14' /> </span>
              </Grid>
              <Grid item xs={6}>
                {/* <TextField
            fullWidth
            value={taskDetails?.dueDate}
            onChange={(e) => {
              taskDetails.dueDate = e.target.value
              forceUpdate();
            }}
            InputLabelProps={{
              shrink: false,
            }}
            sx={{
              '& label.Mui-focused': {
                color: 'white',
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: 'white',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'white',
                },
                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'white  ',
                },
              },
            }} size="small"
            label={""}
            type={"date"}
            variant="outlined"
          /> */}


                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
                  <DatePicker
                    disabled={taskDetails.status === StatusesConsts.CLOSED_STATUS_ID}
                    shouldDisableDate={(date) =>
                      date.isBefore(moment(), 'day')
                    }
                    value={taskDetails.dueDate ? moment(taskDetails.dueDate, 'YYYY-MM-DD') : null}
                    onChange={handleDateChange}
                    format="DD/MM/YYYY"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: dueDateError,
                        sx: {
                          '& label.Mui-focused': {
                            color: 'white',
                          },
                          '& .MuiInput-underline:after': {
                            borderBottomColor: 'white',
                          },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: dueDateError ? 'red' : 'white',
                              borderRadius: '8px'
                            },
                            '&:hover fieldset': {
                              borderColor: 'white',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'white',
                            },
                          },
                        },
                        size: "small",
                        variant: "outlined"
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              {/* <Grid item xs={6}>
                <span className='labels-extra-bold font-14'><Message id="task.module" className='labels-extra-bold font-14' /></span>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  select
                  value={taskDetails.module}
                  disabled
                  onChange={(e) => {
                    taskDetails.module = parseInt(e.target.value)
                    forceUpdate();
                  }}
                  sx={{
                    '& label.Mui-focused': {
                      color: 'white',
                    },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: 'white',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'white',
                      },
                      '&:hover fieldset': {
                        borderColor: 'white',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'white  ',
                      },
                    },
                  }}
                  InputProps={{ className: 'fieldDesign' }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 150, 
                        },
                      },
                    },
                  }}
                >
                  {moduleList?.map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>


              </Grid> */}

              <Grid item xs={6}>
                <span className='labels-extra-bold font-14'><Message id="task.priority" className='labels-extra-bold font-14' /></span>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  select
                  value={taskDetails?.priority}
                  disabled={taskDetails.status === StatusesConsts.CLOSED_STATUS_ID}
                  defaultValue={3}
                  onChange={(e) => {
                    taskDetails.priority = e.target.value
                    forceUpdate();
                  }}
                  sx={{
                    '& label.Mui-focused': {
                      color: 'white',
                    },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: 'white',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'white',
                      },
                      '&:hover fieldset': {
                        borderColor: 'white',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'white  ',
                      },
                    },
                  }}
                  InputProps={{ className: 'fieldDesign' }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 150, // Set the max height of the dropdown
                        },
                      },
                    },
                  }}
                >
                  {prioritiesList?.map((option: any) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>


              </Grid>

              <Grid item xs={6}>
                <span className='labels-extra-bold'><Message id="task.recurring-task" className='Field-input font-weight-700 font-14' /></span>
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  checked={iseRecuringTask}
                  disabled={taskDetails.status === StatusesConsts.CLOSED_STATUS_ID}
                  control={<IOSSwitch sx={{ m: 1 }}
                    onChange={handleRecuringChange}
                  />}
                  label=""
                />
              </Grid>
              {iseRecuringTask ?
                <>
                  <Grid item xs={6}>
                    <span className='labels-extra-bold'><Message id="task.recurring-period" className='Field-input font-weight-700 font-14' /></span>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      select
                      value={taskDetails?.recurringPeriod}
                      disabled={taskDetails.status === StatusesConsts.CLOSED_STATUS_ID}
                      onChange={(e) => {
                        taskDetails.recurringPeriod = e.target.value
                        forceUpdate();
                      }}
                      sx={{
                        '& label.Mui-focused': {
                          color: 'white',
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: 'white',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'white',
                          },
                          '&:hover fieldset': {
                            borderColor: 'white',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'white  ',
                          },
                        },
                      }}
                      InputProps={{ className: 'fieldDesign' }}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: 150, // Set the max height of the dropdown
                            },
                          },
                        },
                      }}
                    >
                      {recurringPeriodsList?.map((option: any) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </>
                : <></>}

              <Grid item container direction='row' alignItems='flex-start'>
                <Grid item xs={6}>
                  <span className='labels-extra-bold'><Message id="task.note" className='Field-input font-weight-700 font-14' /></span>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    disabled={taskDetails.status === StatusesConsts.CLOSED_STATUS_ID}
                    multiline
                    rows={3}
                    value={taskDetails?.note}
                    onChange={(e) => {
                      taskDetails.note = e.target.value
                      forceUpdate();
                    }}
                    sx={{
                      '& label.Mui-focused': {
                        color: '#C3C2C7',
                      },
                      '& .MuiInput-underline:after': {
                        borderBottomColor: '#B2BAC2',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#C3C2C7',
                        },
                        '&:hover fieldset': {
                          borderColor: '#B2BAC2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#6F7E8C',
                        },
                      },
                    }}
                    InputProps={{ className: 'descriptionDesign' }}
                    inputProps={{ maxLength: 10000 }}
                  />
                </Grid>
              </Grid>

              <Grid item container justifyContent='flex-end' alignItems='center' >
                <button
                  className={actionCallFrom === 'CompleteTask' ? callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'blueButton' : 'blueButton'}
                  style={{ width: '200px', padding: '20px' }}
                  disabled={loading}
                  onClick={() => {
                    // if (taskDetails.status !== StatusesConsts.CLOSED_STATUS_ID) {
                      // taskDetails.markedAsDone = true;
                      // taskDetails.status = StatusesConsts.CLOSED_STATUS_ID
                      dispatch(setActionCallFrom('CompleteTask'))
                      // forceUpdate();
                      // updateTaskCall();
                      openOrCompleteTaskCall(taskDetails.id)

                    // }
                    // else if (taskDetails.status === StatusesConsts.CLOSED_STATUS_ID) {
                    //   taskDetails.status = StatusesConsts.OPEN_STATUS_ID
                    //   forceUpdate();
                    //   dispatch(setActionCallFrom('CompleteTask'))
                    //   updateTaskCall();
                    // }

                  }}
                >
                  {loading && actionCallFrom === 'CompleteTask' ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                    : callStatus === 'Pass' && actionCallFrom === 'CompleteTask' ? <span><CheckCircleOutlinedIcon style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                      : callStatus === 'Fail' && actionCallFrom === 'CompleteTask' ? "Failed"
                        :
                        taskDetails.status !== StatusesConsts.CLOSED_STATUS_ID ?
                          <><CheckCircleOutlineOutlinedIcon />Complete Task</>
                          :
                          <><CheckCircleOutlineOutlinedIcon />Open Task</>
                  }
                </button>
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Grid item container rowGap={1.5} padding={1.5} maxWidth='130px'>
                        {userRoles?.includes(userRolesConst.ADMIN_ID)
                          || userRoles?.includes(userRolesConst.TAX_ADVISOR_ID)
                          || userRoles?.includes(userRolesConst.CLIENT_ADMIN_ID) ?
                          <Grid item container direction='row' className='red labels pointerText' alignItems='center' columnGap={1}
                            onClick={() => {
                              setselectedTaskToDelete(() => ({
                                id: taskDetails.id,
                                title: taskDetails.title
                              }));
                              setOpenDeleteDialog(true);
                            }}
                          ><DeleteOutlineIcon htmlColor='#EF4737' fontSize='small' /><Message id="ta.delete" className="Field-input labelsLinks font-weight-400" /></Grid>
                          :
                          <></>
                        }
                        <Grid item container direction='row' className='neutral-4 labels pointerText' alignItems='center' columnGap={1}
                          onClick={() => {
                            setTaskObject(() => ({
                              title: `${taskDetails.title} (copy)`,
                              status: 1,
                              description: taskDetails.description,
                              note: taskDetails.note,
                              recurringPeriod: taskDetails.recurringPeriod,
                              priority: taskDetails.priority,
                              dueDate: taskDetails.dueDate ? moment(taskDetails.dueDate).format('YYYY-MM-DD') : null,
                              type: taskDetails.type,
                              clientId: taskDetails.clientId,
                              assigneeId: taskDetails.assigneeId,
                              module: taskDetails.module
                            }))
                            setOpen(true);
                          }}
                        >
                          <ContentCopyOutlinedIcon htmlColor='#605e6b' fontSize='small' /> Duplicate
                        </Grid>

                      </Grid>
                    </React.Fragment>
                  }
                >
                  <Grid item style={{ cursor: 'pointer' }}><MoreVertIcon htmlColor='#93919a' /></Grid>
                </HtmlTooltip>
              </Grid>

              {/* <Grid item xs={6}>
          <span className='labels-extra-bold'><Message id="task.relevant-period" className='Field-input font-weight-700 font-14' /></span>
        </Grid>
        <Grid item xs={6}>
          N/A
        </Grid>
        <Grid item xs={6}>
          <span className='labels-extra-bold'><Message id="task.priority" className='Field-input font-weight-700 font-14' /></span>
        </Grid>
        <Grid item xs={6}>
          N/A
        </Grid>
       */}

            </Grid>
            
          </Grid>
          :
          <></>
      }
    </>

  )
}

export default TaskDetailsContainer