import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export type Locale = 'en' | 'gr' | 'ar';

export interface LocaleState {
    locale: Locale;
    dir: string;
}

const initialState: LocaleState = {
    locale: 'en',
    dir: 'ltr'
};

export const localeSlice = createSlice({
    name: 'locale',
    initialState,
    reducers: {
        setLocale(state, data: PayloadAction<Locale>) {
            state.locale = data.payload;
            state.dir = data.payload === 'ar' ? 'rtl' : 'ltr';
        }
    }
});

export const { setLocale } = localeSlice.actions;

export const selectLocale = (state: RootState) => state.locale.locale;

export const selectDir = (state: RootState) => state.locale.dir;

export default localeSlice.reducer;