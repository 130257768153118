import * as apiConst from "../taxmation-consts";
import axios from "axios";

const INVITE_CLIENT = apiConst.INVITE_CLIENT;
export const inviteClient = (clientsList :{}) => {
    return axios
        .post(`${process.env.REACT_APP_URL + INVITE_CLIENT}`, clientsList,
            {
                withCredentials: true,
            }   
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}

const ADD_TM = apiConst.ADD_TM;
export const addTM = (tmList :{}) => {
    return axios
        .post(`${process.env.REACT_APP_URL + ADD_TM}`, tmList,
            {
                withCredentials: true,
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}


