import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { Grid } from '@mui/material';
import loadingImg from '../../Admin/assets/images/payment/loading.png';
import failImg from '../../Admin/assets/images/payment/fail.png'
import successImg from '../../Admin/assets/images/payment/success.png'
import { useNavigate } from 'react-router-dom';
import { setIsSubscribed } from '../../app/slices/userDataSlice';
import { useAppDispatch } from '../../app/hooks';

export interface StatusDialogProps {
  open: boolean;
  status: string;
  onClose: () => void;
}

export default function StatusDialog(props: StatusDialogProps) {
  const { onClose, open, status } = props;
  const dispatch = useAppDispatch()

  const handleClose = () => {
    onClose();
  };

  const navigate = useNavigate();

  return (
    <Dialog onClose={handleClose} open={open}>
      <Grid item container direction='column' rowGap={2} className='dialogContainer' padding={4} justifyContent='center' alignItems='center'>
        {status === 'loading' ?
          <>
            <Grid item>
              <img src={loadingImg} height='318px' width='318px' alt='icon' />
            </Grid>
            <Grid item className='neutral-3 desktop-header-3'> Awaiting confirmation...</Grid>
            <Grid item className='neutral-6 labels font-weight-400' textAlign='center'>
              This can take up to 2 minutes. <br /> Thanks for your patience.
            </Grid>
          </>
          : status === 'fail' ?
            <>
              <Grid item>
                <img src={failImg} height='318px' width='318px' alt='icon' />
              </Grid>
              <Grid item className='neutral-3 desktop-header-3 errorText'> Payment unsuccessful</Grid>
              <Grid item width='100%'>
                <button className='redButton' onClick={() => handleClose()}>Retry</button>
              </Grid>
            </>
            : status === 'success' ?
              <>
                <Grid item>
                  <img src={successImg} height='318px' width='318px' alt='icon' />
                </Grid>
                <Grid item className='neutral-3 desktop-header-3' style={{ color: '#00B69B  ' }}> Payment Successful</Grid>
                <Grid item width='100%'>
                  <button className='successButton' onClick={() => {
                    localStorage.setItem("isSubscribed", 'true');
                    dispatch(setIsSubscribed(true));
                    navigate('/users')

                  }}>Click to proceed</button>
                </Grid>
              </>
              :
              <></>
        }
      </Grid>
    </Dialog>
  );
}

