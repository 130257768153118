import React, { useState } from 'react';
import { Grid } from '@mui/material';
import VerificationInput from "react-verification-input";
import Message from '../../sharedComponents/ui/Message';

const VerifyUser = () => {

    const [code, setCode] = useState<any>();
    const [isValid, setIsValid] = useState<boolean>(false);

    const handleComplete = async (value: string) => {
        setCode(value);
        setIsValid(true);
    };

    return (
        <Grid item container direction='column' marginTop={6} rowGap={5} justifyContent='center' alignItems='center' color='black'>
            <VerificationInput
                placeholder=''
                validChars='0-9'
                inputProps={{ inputMode: "numeric" }}
                classNames={{
                    container: 'container',
                    character: isValid ? 'character invalid' : 'character valid',
                }}
                onComplete={handleComplete}
            />
            {isValid && <span className="errorText">The code you entered is incorrect.</span>}

            <Grid item width='100%'><button className='blueButton'><Message id="button.continue" className='labels-extra-bold Field-input'/></button></Grid>

        </Grid>
    )
}

export default VerifyUser
