import * as apiConst from "../taxmation-consts";
import axios from "axios";


const GET_INVITED_CLIENTS = apiConst.GET_INVITED_CLIENTS;

export const getInvitedClients = (listData:{}) => {
    return axios
        .post(`${process.env.REACT_APP_URL + GET_INVITED_CLIENTS}`,
            listData,
            {
                withCredentials: true,
            }
            )
        .then((response) => {
            return response.data;
        })
    .catch((error) => {
        return error.response.data
    })
}

const DELETE_CLIENT = apiConst.DELETE_CLIENT;
export const deleteClient = (clientId: string) => {
    return axios
        .delete(`${process.env.REACT_APP_URL + DELETE_CLIENT}?clientId=${clientId}`,
            {
                withCredentials: true,  // Include cookies
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}
const ADD_TEAM_MEMBERS= apiConst.ADD_TEAM_MEMBERS;
export const addTeamMembers=(tmList:{})=>{
return axios
.post(`${process.env.REACT_APP_URL + ADD_TEAM_MEMBERS}`,tmList,
    {
        withCredentials:true,
    }
)
.then((response)=>{
    return response.data;
})
.catch((error)=>{
    return error.response.data;
})
}