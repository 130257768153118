import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import classes from "../../assets/styles/Login.module.css";
import SSOLogin from './SSOLogin';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import EmailLogin from './EmailLogin';
import SSOFail from './SSOFail';
import Message from '../../sharedComponents/ui/Message';
import VerifyUser from './VerifyUser';
// import PlanSelection from '../../pages/PlanSelection';
import mailIcon from "../../assets/images/icons/mail.png";
import googleIcon from "../../assets/images/icons/google.png";


const LoginContainer = ({ loginFormState, setLoginFormState }: { loginFormState: string, setLoginFormState: any }) => {

  const formState = localStorage.getItem('loginFormState');

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const [inviterName, setInviterName] = useState<any>();
  const userType = query.get("type");

  useEffect(() => {
    if (query.get("inviter")) {
      setInviterName(query.get("inviter")?.toUpperCase())
    }
  }, [])

  return (
    <Grid item container direction="column" className={classes['loginContainer']} width='460px' alignItems='center'>
      <Grid item container width='370px'>
        <Grid item className={formState === 'SSO-fail' ? 'neutral-6 desktop-header-5' : 'neutral-6 labels'}>
          {formState === 'SSO-fail' ?
            <Message
              id="error.sso-login-unsucessful"
              className='neutral-6 desktop-header-5'
            />
            :
            formState === 'verify-user' ?
              <Message
                id="login.enter-your-code"
                className='neutral-6 desktop-header-5 '
              />
              : formState === 'plan-select' ?
                <Message
                  id="login.thank-you-for-choosing-taxmation"
                  className='neutral-6 labels'
                />
                : inviterName ?
                  <Grid item container direction='row' columnGap={1} alignItems='center'>
                    <Message
                      id="login.youve-been-invited-by"
                      className='neutral-6 labels'
                    />
                    <span className='iris-100 labels-extra-bold'>{inviterName}</span>
                  </Grid>

                  :
                  <Message
                    id="login.welcome-back"
                    className='neutral-6 labels'
                  />
          }
        </Grid>
        <Grid item className={formState === 'SSO-fail' ? 'neutral-6 labels' : 'neutral-6 desktop-header-5'} width='370px'>
          {formState === 'SSO-fail' ?
            <Message
              id="error.something-went-wrong"
              className='neutral-6 labels'
            />
            :
            formState === 'verify-user' ?
              <Message
                id="login.we-sent-a-verification-code-to"
                className='neutral-6 labels'
              />
              : formState === 'plan-select' ?
                <Message
                  id="login.welcome-to-tax-made-easy"
                  className='neutral-6 desktop-header-5'
                />
                : inviterName ?
                  <Message
                    id="login.link-your-account-with-sso"
                    className='neutral-6 desktop-header-5'
                  />
                  :
                  <Message
                    id="login.sign-in-to-your-account"
                    className='neutral-6 desktop-header-5'
                  />
          }
        </Grid>
        {formState === 'default' ?
          <Grid container marginTop={6}>
            <SSOLogin loginFormState={loginFormState} setLoginFormState={setLoginFormState} />
            <Grid item container direction="row" paddingTop={2} paddingBottom={2} justifyContent='center' alignItems='center'>
              <Grid item xs={5} className={classes['greyLine']}></Grid>
              <span className='neutral-6' style={{ width: '50px', textAlign: 'center' }}>
                <Message
                  id="text.or"
                  className='neutral-6'
                />
              </span>
              <Grid item xs={5} className={classes['greyLine']}></Grid>
            </Grid>
            <Grid item>
              <button className={classes['loginButton']} onClick={() => {
                    setLoginFormState('email-login')
                    localStorage.setItem('loginFormState', 'email-login')
                  }} disabled={userType && parseInt(userType) === 1 ? true : false}>
                <Grid item container textAlign='start' alignItems='center'
                  >
                  <img src={mailIcon} alt='icon' style={{ paddingRight: '100px', paddingLeft: '4px' }} width='18px' height='16px' />
                  <Message
                    id="login.sign-in-with-email"
                  />
                </Grid>
              </button>
            </Grid>
          </Grid>
          : formState === 'email-login' ?
            <EmailLogin loginFormState={loginFormState} setLoginFormState={setLoginFormState} />
            : formState === 'SSO-fail' ?
              <SSOFail setLoginFormState={setLoginFormState} />
              : formState === 'verify-user' ?
                <VerifyUser />
                // : formState === 'plan-select' ?
                //   <PlanSelection />
                :
                <></>
        }
      </Grid>
    </Grid>
  )
}

export default LoginContainer