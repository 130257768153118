import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import { Grid, MenuItem, TextField } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Logo from "../assets/images/logoGrey.png";
import LetterLogo from "../assets/images/letterLogo.png";
import { Locale, selectLocale, setLocale } from '../app/slices/localsSlice';
import { selectIsLoggedIn, selectIsCMS, selectIsSSO, selectIsSubscribed, selectIsOtpEnabled, selectIsOtpVerified, selectUserType, selectRoles } from '../app/slices/userDataSlice';
import LoginPage from '../pages/LoginPage';
import PageNotFound from '../pages/PageNotFound';
import SideNavbar from './SideNavbar';
import UpperNavbar from './UpperNavbar';
import AdminManageUsers from '../Admin/pages/ManageUsers';
// import EditProfile from '../Admin/pages/EditProfile';
import EditProfile from '../pages/EditProfile';
import Payment from '../pages/Payment';
import TwoFactorAuthentication from '../pages/TwoFactorAuthentication';
import PlanSelection from '../pages/PlanSelection';
import ManageUsers from '../pages/Dashboard/ManageUsers';
import AddUsers from '../pages/Dashboard/AddUsers';
import * as userRolesConst from "../user-roles-consts";
import ManageTasks from '../pages/Dashboard/ManageTasks/ManageTasks';
import TaskDetails from '../pages/Dashboard/ManageTasks/TaskDetails';

const drawerWidth = '15vw';

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  // width: `calc(${theme.spacing(7)} + 1px)`,
  // [theme.breakpoints.up('sm')]: {
  //   width: `calc(${theme.spacing(8)} + 1px)`,
  // },
  width: '4vw'
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


const Navigate = () => {

  const [open, setOpen] = useState<any>(false);
  const [modifyProfile, setModifyProfile] = useState<boolean>(false);
  const [loginFormState, setLoginFormState] = useState<string>();

  const dispatch = useAppDispatch();

  const locale = useAppSelector(selectLocale);
  const isCMS = useAppSelector(selectIsCMS);
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const userType = useAppSelector(selectUserType);
  const isSubscribed = useAppSelector(selectIsSubscribed);
  const isSSO = useAppSelector(selectIsSSO);
  const isOtpEnabled = useAppSelector(selectIsOtpEnabled);
  const isOtpVerified = useAppSelector(selectIsOtpVerified);
  const userRoles = useAppSelector(selectRoles);

  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!localStorage.getItem('loginFormState')) {
      localStorage.setItem('loginFormState', 'default');
      setLoginFormState('default')
    }
  })

  return (
    <Grid container>
      <Grid item container justifyContent='flex-end' position='absolute' top='2%' right='50%' zIndex={3}>
        <TextField
          defaultValue={locale}
          select
          SelectProps={{ MenuProps: { disableScrollLock: true } }}
          InputProps={{
            style: {
              borderRadius: '12px',
              fontFamily: 'DM Sans',
              height: '50px',
              borderTopLeftRadius: '10px',
              backgroundColor: '#F7F9FF',
              width: '100%'
            },
          }}
          sx={{
            '& label.Mui-focused': {
              color: '#C3C2C7',
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: '#B2BAC2',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#C3C2C7',
              },
              '&:hover fieldset': {
                borderColor: '#B2BAC2',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#6F7E8C',
              },
            },
          }}
          onChange={(e) => {
            dispatch(setLocale(e.target.value as Locale));

          }}
        >
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="gr">German</MenuItem>
        </TextField>
      </Grid>
      {isLoggedIn && (isCMS || (!isCMS && isOtpEnabled && isOtpVerified && ((userType === 1 && isSubscribed) || userType === 2))) ?
        <>
          <Grid item position='absolute' top='10%' left={open ? '14%' : '3.2%'} zIndex={2}
            style={{ backgroundColor: '#FEFEFF', boxShadow: '0px 4px 4px 0px #0000001F', width: '34px', height: '34px', borderRadius: '50px', textAlign: 'center', cursor: 'pointer' }}
            onClick={() => {
              if (open) handleDrawerClose()
              else handleDrawerOpen()
            }
            }
          >
            {open ?
              <ArrowBackIosIcon style={{ color: '#93919A', marginLeft: 9, marginTop: 7 }} />
              :
              <ArrowForwardIosIcon style={{ color: '#93919A', marginLeft: 6, marginTop: 7 }} />
            }
          </Grid>
          <Box sx={{ display: 'flex', zIndex: 1, position: 'relative' }}>
            <Drawer variant="permanent" open={open}>
              <DrawerHeader onClick={() => navigate('/admin-panel/users')} onMouseOver={(e) => {
                const target = e.target as HTMLElement;
                target.style.cursor = 'pointer';
              }}>
                {open ? <img src={Logo} alt='logo' width={200} /> :
                  <img src={LetterLogo} alt='logo' width="200px" />
                }
              </DrawerHeader>
              <SideNavbar open={open} />
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1 }}>
              <DrawerHeader>
                <Grid container height='10vh'>
                  <UpperNavbar
                    modifyProfile={modifyProfile}
                  />
                </Grid>
              </DrawerHeader>
              <Grid container bgcolor='#F7F9FF' width={open ? '85vw' : '96vw'} padding={4} height='90vh' overflow='auto'>
                {isLoggedIn && isCMS ?
                  <Routes>
                    <>
                      <Route
                        path="/admin-panel/users"
                        element={<AdminManageUsers />}
                      />
                      <Route
                        path="/admin-panel/profile"
                        element={<EditProfile modifyProfile={modifyProfile} setModifyProfile={setModifyProfile} />}
                      />
                      {window.location.pathname.toString() !== "/admin-panel/profile" || window.location.pathname.toString() !== "/admin-panel/users" ?
                        <Route path="*" element={<PageNotFound cmsOpen={open} />} />
                        :
                        <></>

                      }
                    </>
                  </Routes>
                  : (isLoggedIn && !isCMS && isOtpEnabled && isOtpVerified && ((userType === 1 && isSubscribed) || userType === 2)) ?
                    // roles.includes(userRolesoConst.SUPER_ADMIN_ID || userRolesoConst.ADMIN_ID)?
                    <Routes>
                      <Route
                        path="/users"
                        element={<ManageUsers />}
                      />
                      <Route
                        path="/add-users"
                        element={<AddUsers />}
                      />
                      <Route
                        path="/tasks"
                        element={<ManageTasks />}
                      />
                        <Route
                        path="/task-details"
                        element={<TaskDetails />}
                      />
                      <Route
                        path="/profile"
                        element={<EditProfile modifyProfile={modifyProfile} setModifyProfile={setModifyProfile} />}
                      />
                    </Routes>
                    //   :
                    //   <Routes>
                    //   <Route
                    //     path="/profile"
                    //     element={<EditProfile modifyProfile={modifyProfile} setModifyProfile={setModifyProfile} />}
                    //   />
                    // </Routes>
                    :
                    <Routes>
                      <Route path="*" element={<PageNotFound cmsOpen={open} />} />
                    </Routes>
                }
              </Grid>
            </Box>
          </Box>
        </>
        : isLoggedIn && !isCMS && !isSubscribed && userType === 1 && isOtpEnabled && isOtpVerified ?
          <Routes>
            <Route path="/plan-selection" element={<PlanSelection />} />
            <Route path="/payment" element={<Payment />} />
          </Routes>
          : isLoggedIn && !isCMS && isSSO && !isOtpVerified ?
            <Routes>
              <Route path="/2fa" element={<TwoFactorAuthentication setLoginFormState={setLoginFormState} />} />
            </Routes>
            :
            <Routes>
              <Route
                path="/"
                element={<LoginPage setLoginFormState={setLoginFormState} loginFormState={loginFormState} />}
              />
              <Route path="*" element={<PageNotFound cmsOpen={open} />} />
            </Routes>
      }

    </Grid>
  )
}

export default Navigate