
interface ProfileImageProps {
  username: string;
  width:string;
  height:string;
  fontSize: string,
  marginRight?: string;
  flexShrink?:number;
}

const ProfileImage = ({ username, width, height, fontSize,marginRight,flexShrink }:ProfileImageProps) => {
  const name = username.split(" ").filter(n => n); 
  const firstNameInitial = name[0] ? name[0][0] : "";
  const lastNameInitial = name[1] ? name[1][0] : "";

  return (
    <span style={{
        display: "flex",
        flexShrink:0,
        justifyContent: "center",
        alignItems: "center",
        width: width,
        height: height,
        marginRight: marginRight,
        backgroundColor: "#5D5FEF",
        color: "#ffffff",
        fontSize: fontSize,
        borderRadius: "50%",
        fontWeight: 800,
    }}>
      {firstNameInitial}
      {lastNameInitial}
    </span>
  );
};

export default ProfileImage;
