import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import { Grid, MenuItem, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import classes from "../../../assets/styles/Task.module.css";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import Message from '../../../sharedComponents/ui/Message';
import moment from "moment";
import 'moment/locale/de';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Stack from '@mui/material/Stack';
import Switch, { SwitchProps } from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useAppSelector } from '../../../app/hooks';
import { selectUserType } from '../../../app/slices/userDataSlice';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export interface DialogProps {
  open: boolean;
  setOpen: CallableFunction;
  loading: any,
  callStatus: any,
  errorMsg: any,
  confirmAction: any,
  clientsList: any;
  typesList: any;
  moduleList:any;
  search: any;
  setSearch:any;
  currentSearch:any;
  selectedClientId:any;
  selectedTypeId:any;
  selectedModule: any;
}
interface FieldsType{
    title: string;
    typeId: number | null;
    module: number | null;
    startDate: string | null;
    endDate: string | null;
    clientId: string | null;
}

const FilterTask = (props: DialogProps) => {

  const { open, setOpen, loading, callStatus, errorMsg, confirmAction, clientsList,typesList,moduleList, search, setSearch, currentSearch,selectedClientId, selectedTypeId, selectedModule } = props;

const [activeButton, setActiveButton]=useState<string>('Tasks');

//Fields
const [fileType,setFileType]= useState('');


const [taskObject, setTaskObject] = useState<FieldsType>({
    title: "",
    typeId: selectedTypeId || null ,
    module: selectedModule || null,
    startDate: null,
    endDate: null,
    clientId: selectedClientId || localStorage.getItem('userType') === '1' ? '' : null,
  });

  const handleOpen = () => {
    setOpen(true)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => {
    // Updating state with an empty object to trigger a re-render
    updateState({});
  }, []);
 

  const handleStartDateChange = (newValue: any) => {
    setTaskObject(prevState => ({
      ...prevState,
      startDate: newValue ? newValue.format('YYYY-MM-DD') : null,
    }));
  };

  const handleEndDateChange = (newValue: any) => {
    setTaskObject(prevState => ({
      ...prevState,
      endDate: newValue ? newValue.format('YYYY-MM-DD') : null,
    }));
  };

  const handleFilter = () => {
    
    const filterPayload = {
      ...taskObject,
      StartDate: taskObject.startDate,
      EndDate: taskObject.endDate,
      typeId: taskObject.typeId || null,
      // fileType: activeButton === 'Files' ? fileType : null,
      search: search,
      clientId: taskObject.clientId || null,
      module: taskObject.module || null
    };
    confirmAction(filterPayload);
    handleClose();
  };

  useEffect(() => {
    if (open) {
      setSearch(currentSearch || '');
      handleOpen();
    }
    else {
      setOpen(false);
      setTaskObject({
        title: "",
        startDate: null,
        endDate: null,
        typeId: null,
        module: null,
        clientId: localStorage.getItem('userType') === '1' ? "" : null,
      });
    }
  }, [open, currentSearch]);

  useEffect(() => {
    if (selectedClientId !== undefined) {
      setTaskObject(prev => ({
        ...prev,
        clientId: selectedClientId || ''
      }));
    }
if (selectedTypeId !== undefined) {
      setTaskObject(prev => ({
        ...prev,
        typeId: selectedTypeId || ''
      }));
    }
 if(selectedModule !== undefined){
  setTaskObject(prev =>({
    ...prev,
    module: selectedModule || ''
  }))
}  }, [selectedClientId, selectedTypeId, selectedModule]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{ sx: { borderRadius: "8px", width: "656px" } }}
    >
      <Grid item container padding={2.5} direction='column' rowGap={2} >
        <Grid item container className='neutral-4' fontWeight={400} alignItems='center' justifyContent='space-between'>
          Filters
          <span style={{ cursor: 'pointer' }} onClick={() => handleClose()}><CloseIcon /></span>
          <Grid item className='greyLine' width='100%' xs={12}></Grid>

        </Grid>
        <Grid item container direction='column' alignItems='center' marginTop='10px'>
          <Grid item className='neutral-9 font-weight-400'>Type</Grid>
        </Grid>

        <Grid item container direction='row' marginTop='15px' columnGap='7px'>
          <Grid item xs={3.9} className='neutral-9 font-weight-400'>
            <button className={activeButton ==='Tasks'?'blueButton':'greyButton'} onClick={()=>{
            setActiveButton('Tasks');
            setFileType('');
            setTaskObject(prev=>({
              ...prev,
              module: null
             }))
            }} style={{width:'100%', height:'48px'}}>Tasks</button>
          </Grid>
          <Grid item xs={3.9} className='neutral-9 font-weight-400'>
            <button className={activeButton ==='Communication'?'blueButton':'greyButton'} onClick={()=>{
            setActiveButton('Communication')
            setTaskObject(prev=>({
              ...prev,
              module: null
             }))
            }} style={{width:'100%', height:'48px'}}>Communication</button>
          </Grid>
          <Grid item xs={3.9} className='neutral-9 font-weight-400'>
            <button className={activeButton ==='Files'?'blueButton':'greyButton'} onClick={()=>{
            setActiveButton('Files')
           setTaskObject(prev=>({
            ...prev,
            typeId: null,
            module: null
           }))
            }} style={{width:'100%', height:'48px'}}>Files</button>
          </Grid>
        </Grid>

        <Grid item container spacing={2} marginTop='15px'>
         {/* {activeButton === 'Communication' && (
        <Grid item xs={12}>Full Text Search</Grid>
         )}    */}
          <Grid item xs={activeButton==='Communication'? 12: 6}> 
          {/* <Grid item xs={6}> */}
            <TextField
             fullWidth 
             value={search}
             label={activeButton==='Communication'?'Search in communication':activeButton==='Tasks'?'Search in tasks':'Search in files'}
              variant="outlined" 
              sx={{
                '& label.Mui-focused': {
                  color: '#C3C2C7',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#B2BAC2',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#C3C2C7',
                  },
                  '&:hover fieldset': {
                    borderColor: '#B2BAC2',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#6F7E8C',
                  },
                },
              }}
              InputProps={{
                style: {
                  borderRadius: '12px',
                  fontFamily: 'DM Sans',
                  height: '50px',
                  borderTopLeftRadius: '10px',
                  backgroundColor: '#F7F9FF',
                },
              }}
              onChange={(e) => setSearch(e.target.value)}
              />
          </Grid>
          {activeButton !=='Communication'&&(
 <Grid item xs={6}>
 <TextField 
 select
 fullWidth 
 value={activeButton ==='Tasks'? taskObject.typeId : fileType}
 label={activeButton === 'Tasks'?'Task Type':'File Type'}
 variant="outlined"
 sx={{
     '& label.Mui-focused': {
       color: '#C3C2C7',
     },
     '& .MuiInput-underline:after': {
       borderBottomColor: '#B2BAC2',
     },
     '& .MuiOutlinedInput-root': {
       '& fieldset': {
         borderColor: '#C3C2C7',
       },
       '&:hover fieldset': {
         borderColor: '#B2BAC2',
       },
       '&.Mui-focused fieldset': {
         borderColor: '#6F7E8C',
       },
     },
   }}
   InputLabelProps={{
    shrink: activeButton === 'Tasks' ? Boolean(taskObject.typeId) : Boolean(fileType)
  }}
   InputProps={{
     style: {
       borderRadius: '12px',
       fontFamily: 'DM Sans',
       height: '50px',
       borderTopLeftRadius: '10px',
       backgroundColor: '#F7F9FF',
     },
   }}
   onChange={(e) => {
    const selectedValue = e.target.value;
    if (activeButton === 'Tasks') {
      setTaskObject(prev=>({
        ...prev,
        typeId : parseInt(e.target.value)
      }))
      forceUpdate();
    } else {
      setFileType(selectedValue);
    }
  }}
 >
   {activeButton === 'Tasks' 
      ? 
            typesList?.map((option: any) => (
                <MenuItem key={option.id} value={option.id}>
                {option.name}
                </MenuItem>
            ))
      : [ 
          <MenuItem key="pdf" value="pdf">PDF</MenuItem>,
          <MenuItem key="doc" value="doc">DOC</MenuItem>,
          <MenuItem key="jpg" value="jpg">JPG</MenuItem>
        ]
    }
 </TextField>
</Grid>
      ) } 
         
        </Grid>
        <Grid item container spacing={2}>
        {localStorage.getItem('userType') === '1' ?
          <Grid item xs={6}>
            <TextField
            select
             fullWidth 
             value={taskObject.clientId} 
             label='Client'
             variant="outlined"
             sx={{
                '& label.Mui-focused': {
                  color: '#C3C2C7',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#B2BAC2',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#C3C2C7',
                  },
                  '&:hover fieldset': {
                    borderColor: '#B2BAC2',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#6F7E8C',
                  },
                },
              }}
              InputProps={{
                style: {
                  borderRadius: '12px',
                  fontFamily: 'DM Sans',
                  height: '50px',
                  borderTopLeftRadius: '10px',
                  backgroundColor: '#F7F9FF',
                },
              }}
              onChange={(e) => {
                setTaskObject(prevState => ({
                  ...prevState,
                  clientId: String(e.target.value),
                }));
              }}
              >
             {clientsList?.map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
            </TextField>
          </Grid>
          :
          <></>}
          <Grid item xs={6}>
            <TextField 
            select
            fullWidth 
            value={taskObject.module}
            label='Module'
             variant="outlined"
             sx={{
                '& label.Mui-focused': {
                  color: '#C3C2C7',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#B2BAC2',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#C3C2C7',
                  },
                  '&:hover fieldset': {
                    borderColor: '#B2BAC2',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#6F7E8C',
                  },
                },
              }}
              InputLabelProps={{
                shrink:  Boolean(taskObject.module)
              }}
              InputProps={{
                style: {
                  borderRadius: '12px',
                  fontFamily: 'DM Sans',
                  height: '50px',
                  borderTopLeftRadius: '10px',
                  backgroundColor: '#F7F9FF',
                },
              }}
              onChange={(e) => {
                  setTaskObject(prev=>({
                    ...prev,
                    module : parseInt(e.target.value)
              }))
                  forceUpdate();
              }}
              >
               {  moduleList?.map((option: any) => (
                <MenuItem key={option.id} value={option.id}>
                {option.name}
                </MenuItem>
            ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid item container direction='row' spacing={2} marginTop='15px'>
        <Grid item xs={12}>Date Range</Grid>
        <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"en-gb"}>
                <Stack spacing={3}>
                  <DatePicker
                  label='From'
                    value={taskObject.startDate ? moment(taskObject.startDate, 'YYYY-MM-DD') : null}
                    onChange={handleStartDateChange}
                    slotProps={{
                      textField: {
                        sx: {
                          '& label.Mui-focused': {
                            color: '#C3C2C7',
                          },
                          '& .MuiInput-underline:after': {
                            borderBottomColor: '#B2BAC2',
                          },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#C3C2C7',
                            },
                            '&:hover fieldset': {
                              borderColor: '#B2BAC2',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#6F7E8C',
                            },
                          },
                        },
                        InputProps: {
                            className: "fieldDesign",
                            style: {
                              borderRadius: '12px',
                              fontFamily: 'DM Sans',
                              height: '50px',
                              borderTopLeftRadius: '10px',
                              backgroundColor: '#F7F9FF',
                            },
                          },
                        },
                      }}
                    format="DD/MM/YYYY"
                  />

                </Stack>
              </LocalizationProvider>
            </Grid>
          <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"en-gb"}>
                <Stack spacing={3}>
                  <DatePicker
                  label='To'
                    value={taskObject.endDate ? moment(taskObject.endDate, 'YYYY-MM-DD') : null}
                    onChange={handleEndDateChange}
                    slotProps={{
                      textField: {
                        sx: {
                          '& label.Mui-focused': {
                            color: '#C3C2C7',
                          },
                          '& .MuiInput-underline:after': {
                            borderBottomColor: '#B2BAC2',
                          },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#C3C2C7',
                            },
                            '&:hover fieldset': {
                              borderColor: '#B2BAC2',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#6F7E8C',
                            },
                          },
                        },
                        InputProps: {
                            className: "fieldDesign",
                            style: {
                              borderRadius: '12px',
                              fontFamily: 'DM Sans',
                              height: '50px',
                              borderTopLeftRadius: '10px',
                              backgroundColor: '#F7F9FF',
                            },
                          },
                        },
                      }}
                    format="DD/MM/YYYY"
                  />

                </Stack>
              </LocalizationProvider>
          </Grid>
        </Grid>

        <Grid item container justifyContent="flex-end">
          <button className='blueButton' style={{width:'120px'}}
          onClick={handleFilter}
          >
            Filter
          </button>
        </Grid>

        

        {errorMsg ?
          <Grid item textAlign='center' className='errorText' paddingTop={1}>{errorMsg}</Grid>
          :
          <></>}
      </Grid>
    </Dialog>
  )
}

export default FilterTask