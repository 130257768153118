import * as apiConst from "../taxmation-consts";
import axios from "axios";

const GET_USER_ROLES = apiConst.GET_USER_ROLES;
export const getUserRoles = () => {
    return axios
        .get(`${process.env.REACT_APP_URL + GET_USER_ROLES}`,
            {
                withCredentials: true,  // Include cookies
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}