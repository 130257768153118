import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';


export interface ModifyState {
   value: boolean
}

const initialState: ModifyState = {
    value: false

};

export const modifySlice = createSlice({
    name: 'modify',
    initialState,
    reducers: {
        setModify(state, data: PayloadAction<boolean>) {
            state.value = data.payload;
        }
    }
});

export const { setModify } = modifySlice.actions;

export const getModifyValue = (state: RootState) => state.modify.value;

export default modifySlice.reducer;