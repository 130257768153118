import React, { useEffect, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import SSOLogin from './SSOLogin';
import classes from "../../assets/styles/Login.module.css";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton } from "@mui/material";
import { CircularProgress } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { loadingActions } from '../../app/slices/loadingSlice';
import { setIsOtpEnabled, setIsOtpVerified, setIsSSO, setIsSubscribed, setUserType, userActions } from '../../app/slices/userDataSlice';
import { useNavigate } from 'react-router-dom';
import * as authServices from "../../services/auth-services.proxy";
import { setIsCMS, setIsLoggedIn } from '../../app/slices/userDataSlice';
import Message from '../../sharedComponents/ui/Message';
import { useIntl } from 'react-intl';
const emailLogin = authServices.emailLogin;

const EmailLogin = ({ loginFormState, setLoginFormState }: { loginFormState: string, setLoginFormState: any }) => {
const intl= useIntl();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState<string>('');

  const [passError, setPassError] = useState<boolean>(false);
  const [passErrorMsg, setPassErrorMsg] = useState<string>('');

  const [BeErrorMsg, setBeErrorMsg] = useState<string>('');

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const loading = useAppSelector((state) => state.loading.value);
  const dispatch = useAppDispatch();
  const navigate = useNavigate(); 

  const login = () => {
    if (!email) {
      setEmailError(true);
      setEmailErrorMsg(intl.formatMessage({ id: 'error.enter-a-valid-email-address' }));
    }
    else if (!password) {
      setPassError(true);
      setPassErrorMsg(intl.formatMessage({ id: 'error.enter-a-password' }))
    }
    else {
      dispatch(loadingActions.setLoading(true));
      emailLogin(email, password).then((x) => {
        dispatch(loadingActions.setLoading(false));
        if (x.ErrorMessage) {
          setBeErrorMsg(x.ErrorMessage)
        }
        else {
          //dispatch(userActions.updateToken(x.accessToken));
          // setLoginFormState('verify-user');
          // localStorage.setItem('loginFormState', 'plan-select')
          // setIsLoggedIn(true);

          localStorage.setItem("isLoggedIn", 'true');
          localStorage.setItem("isCMS", x.isCMS);
          dispatch(setIsCMS(x.isCMS));
          dispatch(setIsLoggedIn(true));
          navigate('/users')

          //dispatch(userActions.setIsCMS(x.isCMS));
          if(email === 'faten.taha@taxmation.com') {
            localStorage.setItem("isLoggedIn", 'true');
            dispatch(setIsLoggedIn(true));
  
            localStorage.setItem("isSSO", 'true');
            dispatch(setIsSSO(true));
  
            localStorage.setItem("isCMS", x.isCMS);
            dispatch(setIsCMS(x.isCMS));
  
            localStorage.setItem("isOtpEnabled", 'true');
            dispatch(setIsOtpEnabled(true));
  
            localStorage.setItem("isOtpVerified", 'true');
            dispatch(setIsOtpVerified(true));
  
            localStorage.setItem("isSubscribed", x.isActivated);
            dispatch(setIsSubscribed(x.isActivated));
  
            localStorage.setItem("userType", '1');
            dispatch(setUserType(1));
          }
          if (x.isCMS) {
            navigate('/admin-panel/users')
          }
          // else {
          //   if (x.isActivated) {
          //     localStorage.setItem("isLoggedIn", 'true');
          //     dispatch(setIsLoggedIn(true));
          //     localStorage.setItem("isSubscribed", 'true');
          //     dispatch(setIsSubscribed(true));
          //     navigate('/users')
          //   }
          //   else {
          //     setLoginFormState('plan-select');
          //     localStorage.setItem('loginFormState', 'plan-select')
          //   }
          //   //localStorage.setItem('loginFormState', 'verify-user')
          // }
        }
        dispatch(loadingActions.setLoading(false));
      })
    }

  }

  return (
    <Grid item container direction='column' marginTop={3} rowGap={3}>
      <Grid item>
        <TextField
          value={email}
          label={<Message id="login.email" className="Field-input labelsLinks neutral-3 font-weight-400" />} 
          placeholder={intl.formatMessage({ id: 'login.enter-your_email-here' })}
          className="Field-input"
          InputLabelProps={{ shrink: true }}
          error={emailError}
          helperText={emailError ? emailErrorMsg : ''}
          size='medium'
          InputProps={{
            style: {
              borderRadius: '12px',
              fontFamily: 'DM Sans',
              height: '50px'
            },
          }}
          onChange={(e) => {
            setEmail(e.target.value)
            if (!e.target.value || !e.target.value.match(
              /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
            )) {
              setEmailError(true);
              setEmailErrorMsg(intl.formatMessage({ id: 'error.enter-a-valid-email-address' }))
            }
            else {
              setEmailError(false);
              setEmailErrorMsg('')
            }
          }}
          onBlur={(e) => {
            if (!e.target.value) {
              setEmailError(true);
              setEmailErrorMsg(intl.formatMessage({ id: 'error.enter-a-valid-email-address' }))
            }
          }}
        />
      </Grid>
      <Grid item>
        <TextField
          type={showPassword ? "text" : "password"}
          value={password}
          label={<Message id="login.password" className="Field-input labelsLinks neutral-3 font-weight-400" />} 
          placeholder={intl.formatMessage({ id: 'login.enter-your-password-here' })}
          className="Field-input"
          InputLabelProps={{ shrink: true }}
          size='medium'
          error={passError} 
          helperText={passError ? passErrorMsg : ''}
          InputProps={{
            style: {
              borderRadius: '12px',
              fontFamily: 'DM Sans',
              height: '50px'
            },
            endAdornment: (
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? (
                  <Visibility htmlColor="rgba(6, 116, 185, 0.5)" />
                ) : (
                  <VisibilityOff htmlColor="rgba(6, 116, 185, 0.5)" />
                )}
              </IconButton>
            ),
          }}
          onChange={(e) => {
            setPassword(e.target.value)
            if (!e.target.value) {
              setPassError(true);
              setPassErrorMsg(intl.formatMessage({ id: 'error.enter-a-password' }))
            }
            else {
              setPassError(false);
              setPassErrorMsg('')
            }
          }}
          onBlur={(e) => {
            if (!e.target.value) {
              setPassError(true);
              setPassErrorMsg(intl.formatMessage({ id: 'error.enter-a-password' }))
            }
          }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              login()
              ev.preventDefault();
            }
          }}
        />

      </Grid>
      <Grid item>
        <button className='blueButton' disabled={!email || !password || emailError || passError || loading}
          onClick={() => {
            // navigate('/dashboard')
            // dispatch(loadingActions.setLoading(true));
            login();
          }}
        >{loading ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span> : <Message id="button.continue" className='labels-extra-bold Field-input' />}</button>
      </Grid>
      {BeErrorMsg ?
        <Grid item className='errorText'>{BeErrorMsg}</Grid> :
        <></>}
      <Grid item container direction="row" paddingTop={0.5} paddingBottom={0.5} justifyContent='center' alignItems='center'>
        <Grid item xs={5} className={classes['greyLine']}></Grid>
        <span className='neutral-6'><Message id="text.or" className='neutral-6' style={{ width: '40px', textAlign: 'center' }} /></span>
        <Grid item xs={5} className={classes['greyLine']}></Grid>
      </Grid>
      <SSOLogin
        loginFormState={loginFormState} setLoginFormState={setLoginFormState}
      //  setIsLoggedIn={setIsLoggedIn} isSSO={isSSO} setIsSSO={setIsSSO} 
      />
    </Grid>
  )
}

export default EmailLogin
