import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

interface loadingState {
    value: boolean,
    dialogLoading: boolean
}

const initialState: loadingState = {
    value: false,
    dialogLoading: false

}

export const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.value = action.payload
        },
        setDialogLoading: (state, action: PayloadAction<boolean>) => {
            state.dialogLoading = action.payload
        },
    },
})

export const loadingActions = loadingSlice.actions

export const {setLoading, setDialogLoading} =loadingSlice.actions;

export const getLoadingState = (state: RootState) => state.loading.value;

export const getDialogLoadingState = (state: RootState) => state.loading.dialogLoading;

export default loadingSlice.reducer