import React from 'react';
import unAuthorized from "../assets/images/403.png";
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Unauthorized = ({ navigateTo }: { navigateTo: any }) => {
  const navigate = useNavigate();
  return (
    <Grid item container direction='column' justifyContent='center' alignItems='center'>
      <Grid item> <img src={unAuthorized} alt='403' /></Grid>
      <Grid item className='neutral-3 text-align-center'>Forbidden <br />
        Request failed due to insufficient permissions</Grid>
      <Grid item paddingTop={3}>
        <button className='blueButton' onClick={() => navigate(navigateTo)}>Go back Home</button>
      </Grid>
    </Grid>
  )
}

export default Unauthorized