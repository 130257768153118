import * as apiConst from "../taxmation-consts";
import axios from "axios";

const GET_COMPANIES_LIST = apiConst.GET_COMPANIES_LIST;
export const getCompaniesList = (listData: {}) => {
    return axios
        .post(`${process.env.REACT_APP_URL + GET_COMPANIES_LIST}`,
            listData,
            {
                withCredentials: true,  // Include cookies
            }
        )
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return error.response.data
        })
}

const GET_USERS_LIST = apiConst.GET_USERS_LIST;
export const getUsersList = (listData: {}) => {
    return axios
        .post(`${process.env.REACT_APP_URL + GET_USERS_LIST}`,
            listData,
            {
                withCredentials: true,  // Include cookies
            }
        )
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return error.response.data
        })
}

const SWITCH_COMPANY_STATUS = apiConst.SWITCH_COMPANY_STATUS
export const switchCompanyStatus = (tenantId: string) => {
    return axios
    .put(`${process.env.REACT_APP_URL + SWITCH_COMPANY_STATUS}?tenantId=${tenantId}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response.data
        })
}
