import React from 'react';
import { Grid } from '@mui/material';
import TaxAdvisorImg from "../assets/images/tax-advisor.png";
import { useNavigate } from 'react-router-dom';
import Message from '../sharedComponents/ui/Message';

const PlanSelection = () => {
  const navigate = useNavigate();

  return (
    <Grid container justifyContent='center' direction='column' alignItems='center' bgcolor='#f3f4f8' height='100vh'>
      <Grid item container direction='column' padding={4} className='dialogContainer' width='30vw' marginTop={2}>
        <Grid item>
          <Message
            id="login.thank-you-for-choosing-taxmation"
            className='neutral-6 labels'
          />
        </Grid>
        <Grid item>   
           <Message
          id="login.welcome-to-tax-made-easy"
          className='neutral-6 desktop-header-5'
        /></Grid>
        <Grid item alignSelf="center">
          <img src={TaxAdvisorImg} alt='img' height='404px' />
        </Grid>
        <Grid item width='166px'  alignSelf="center"><button className='blueButton' style={{ padding: '15px' }}
          onClick={() => navigate('/payment')}>Select Plan</button></Grid>
      </Grid>
    </Grid>

  )
}

export default PlanSelection