
export const CREATION_CATEGORY_ID = 1;
export const STATUS_CATEGORY_ID = 2;
export const TYPE_CATEGORY_ID = 3;
export const TITLE_CATEGORY_ID = 4;
export const DESCRIPTION_CATEGORY_ID = 5;
export const ASSIGNEE_CATEGORY_ID = 6;
export const DUE_DATE_CATEGORY_ID = 7;
export const PRIORITY_CATEGORY_ID = 8;
export const DELETE_DOCUMENT_CATEGORY_ID = 9;
export const UPLOAD_DOCUMENT_CATEGORY_ID = 10;


export const CREATION_CATEGORY_NAME = 'Creation';
export const STATUS_CATEGORY_NAME = 'Status';
export const TYPE_CATEGORY_NAME = 'Type';
export const TITLE_CATEGORY_NAME = 'Title';
export const DESCRIPTION_CATEGORY_NAME = 'Description';
export const ASSIGNEE_CATEGORY_NAME = 'Assignee';
export const DUE_DATE_CATEGORY_NAME = 'DueDate';
export const PRIORITY_CATEGORY_NAME = 'Priority';
export const DELETE_DOCUMENT_CATEGORY_NAME = 'DeleteDocument';
export const UPLOAD_DOCUMENT_CATEGORY_NAME = 'UploadDocument';
