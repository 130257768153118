import * as apiConst from "../taxmation-consts";
import axios from "axios";

const EMAIL_LOGIN = apiConst.EMAIL_LOGIN;
export const emailLogin = (email: string, password: string) => {
    return axios
        .post(`${process.env.REACT_APP_URL + EMAIL_LOGIN}`,
            {
                email: email,
                password: password
            },
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}

const GOOGLE_LOGIN = apiConst.GOOGLE_LOGIN
export const googleLogin = (tokenId: string, tenantId: string, refreshToken: string) => {
    return axios
        .post(`${process.env.REACT_APP_URL + GOOGLE_LOGIN}`,
            {
                tokenId: tokenId,
                tenantId: tenantId?tenantId:null,
                refreshToken: refreshToken
            })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}

const MICROSOFT_LOGIN = apiConst.MICROSOFT_LOGIN
export const microsoftLogin = (token: string, tenantId: string) => {
    return axios
        .post(`${process.env.REACT_APP_URL + MICROSOFT_LOGIN}`,
            {
                tokenId: token,
                tenantId: tenantId?tenantId:null,
            })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}

const DATEV_LOGIN = apiConst.DATEV_LOGIN
export const datevLogin = (code: string) => {
    const stringCode = String(code)
    return axios
        .post(`${process.env.REACT_APP_URL + DATEV_LOGIN}`,JSON.stringify(stringCode),
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}

const TEST_API = apiConst.TEST_API
export const testApi = () => {
    return axios
        .get(`${process.env.REACT_APP_URL + TEST_API}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response.data
        })
}



