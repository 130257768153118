import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import { Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import classes from "../../../../assets/styles/User.module.css";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import Message from '../ui/Message';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        boxShadow: '0px 2px 4px 0px #0000001F',
        backgroundColor: '#FEFEFF',
        borderRadius: '10px',
        padding: 0,
        color: 'black',
    },
}));

export interface DialogProps {
    openDeleteDialog: boolean;
    setOpenDeleteDialog: CallableFunction;
    loading: any,
    callStatus: any,
    errorMsg: any,
    confirmAction: any;
    object: any;
    type: any;
}

const DeleteDialog = (props: DialogProps) => {

    const { openDeleteDialog, setOpenDeleteDialog, loading, callStatus, errorMsg, confirmAction, object, type } = props;

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpenDeleteDialog(true);
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false);
        setOpenDeleteDialog(false);
    };

    useEffect(() => {
        if (openDeleteDialog) {
            handleOpen();
        }
        else {
            setOpen(false);
        }
    }, [openDeleteDialog]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{ sx: { borderRadius: "15px", width: '60%' } }}

        >
            <Grid item container direction='column' padding={3} rowGap={1} justifyContent='center' textAlign='center'>
                <Grid item container className='black labels bold' fontWeight={400} alignItems='center' justifyContent='space-between'>
                    {type === 'task' ? " Delete Task" : <Message id="button.delete-user" className="Field-input labels font-weight-400" />}
                    <span style={{ cursor: 'pointer' }} onClick={() => handleClose()}><CloseIcon /></span>
                </Grid>
                <Grid item className='greyLine' width='100%'></Grid>
                <Grid item container alignItems='center' className='neutral-9 desktop-header-6 font-weight-400' paddingTop={1.5} sx={{ display: 'flex', flexWrap: 'wrap', gap: '4px', justifyContent: 'center' }}>
                    {type === 'task' ? <><Message id="task.you-have-chosen-to-delete" className="neutral-9 desktop-header-6 font-weight-400" /> <span className='bold'>{object?.title}</span> </> :
                        <>
                            <Message id="text.you-have-chosen" className="neutral-9 desktop-header-6 font-weight-400" />  <span className='bold'> {object?.email}</span>

                            <Message id="text.with-role" className="neutral-9 desktop-header-6 font-weight-400" />  <span className='bold'>{object?.roles} </span> <Message id="text.from-taxmation" className="neutral-9 desktop-header-6 font-weight-400" />
                        </>}

                </Grid>
                {type === 'task' ? "" :
                <Grid item container alignItems='center' className='neutral-9 desktop-header-6 font-weight-400 ' paddingTop={1.5} sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'center' }}>
                <Message id="text.all" className="neutral-9 desktop-header-6 font-weight-400" />{type ==='team member'?<span className='bold'>{object.taskCount}  </span>:''}<Message id="text.all-task-assigned" className="neutral-9 desktop-header-6 font-weight-400" /> <span className='bold'><Message id="text.unassigned" className="bold" /></span>
                </Grid>
}
                <Grid item marginTop={1}> <Message id="text.please-confirm" className="neutral-9 desktop-header-6 font-weight-400" /></Grid>
                <Grid item container direction='row' justifyContent='center' marginTop={2} marginBottom={2} columnGap={1.5}>
                    {errorMsg ?
                        <Grid item textAlign='center' className='errorText' paddingTop={1}>{errorMsg}</Grid>
                        :
                        <></>
                    }
                    <Grid item xs={3} >
                        <button className='greyButton' onClick={() => handleClose()}> <Message id="button.cancel" className="labels-extra-bold labelsLinks Field-input" /></button>
                    </Grid>
                    <Grid item xs={3}>
                        <button
                            className={callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'deleteButton'}
                            disabled={loading}
                            onClick={() => confirmAction()}
                        >
                            {loading ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                                : callStatus === 'Pass' ? <CheckCircleOutlinedIcon style={{ color: 'white', width: '15px', height: '15px' }} />
                                    : callStatus === 'Fail' ? "Failed"
                                        :
                                        type === 'task' ?  <Message id="button.delete-task" className="labels-extra-bold labelsLinks Field-input" /> : <Message id="button.delete-user" className="labels-extra-bold labelsLinks Field-input" />}
                        </button>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default DeleteDialog